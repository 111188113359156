export const firebase = {
    firebaseConfig : {
        apiKey: "AIzaSyDgjVVke3r7JVvq5YzpuwpCM1Epbmvb1xk",
        authDomain: "ipos-store.firebaseapp.com",
        projectId: "ipos-store",
        storageBucket: "ipos-store.appspot.com",
        messagingSenderId: "818068006488",
        appId: "1:818068006488:web:882d633e1431bf0167cc2d",
        measurementId: "G-RQFDV3XL67",
      },
}