<template>
  <!-- template for the modal component -->
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" style="position: relative; padding:5px;">
          <!-- close button -->
          <!-- <div @click="$emit('close')" class="modal-close-button">
              <fas icon="times"></fas>
          </div> -->

          <!-- content -->
          <div
            style="font-size:22px; font-weight: bold; text-align:right; color:var(--main-color)"
          >
            {{ equation }}
          </div>
          <div class="modal-bar">
            <div style="display:flex"></div>
            <div class="row-key">
              <div class="button-key" style="background-color:red" @click="keypress('c')">
                <div class="key-container">
                    C
                </div>
              </div>

              <div
                class="button-key"
                style="background-color: var(--main-color)"
                @click="keypress('delete')"
              >
                <div class="key-container">⌫</div>
              </div>

              <div
                class="button-key"
                style="background-color:var(--main-color)"
                @click="close"
              >
                <div class="key-container">✓</div>
              </div>

             
              
            </div>

            <div class="row-key">
              <div class="button-key" style="background-color: grey" @click="keypress('1')">
                <div class="key-container">1</div>
              </div>

              <div class="button-key" style="background-color: grey" @click="keypress('2')">
                <div class="key-container">2</div>
              </div>

              <div class="button-key" style="background-color: grey" @click="keypress('3')">
                <div class="key-container">3</div>
              </div>
            </div>

            <div class="row-key">
              <div class="button-key" style="background-color: grey" @click="keypress('4')">
                <div class="key-container">4</div>
              </div>

              <div class="button-key" style="background-color: grey" @click="keypress('5')">
                <div class="key-container">5</div>
              </div>

              <div class="button-key" style="background-color: grey" @click="keypress('6')">
                <div class="key-container">6</div>
              </div>
            </div>

            <div class="row-key">
              <div class="button-key" style="background-color: grey" @click="keypress('7')">
                <div class="key-container">7</div>
              </div>

              <div class="button-key" style="background-color: grey" @click="keypress('8')">
                <div class="key-container">8</div>
              </div>

              <div class="button-key" style="background-color: grey" @click="keypress('9')">
                <div class="key-container">9</div>
              </div>
            </div>

            <div class="row-key">
              <div class="button-key" style="background-color: grey" @click="keypress('0')">
                <div class="key-container">0</div>
              </div>

              <div class="button-key" style="background-color: grey" @click="keypress('00')">
                <div class="key-container">00</div>
              </div>

              <div class="button-key" style="background-color: grey" @click="keypress('.')">
                <div class="key-container">.</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalNumPad",
  props: {
    initnum: {
      typeof: Number,
      required: true,
    },
    options:{
      typeof: Object,
      required: true
    },
    onClose: Function,
  },
  data() {
    return {
      initWorking: true,
      equation: '',
    };
  },
  methods: {
    keypress(buttonText) {
      
      if (buttonText == "c") {
        this.equation = '0';
      } else if (buttonText == "delete") {
        this.equation = this.equation.toString().substring(0, this.equation.length - 1);
        if (this.equation == "") {
          this.equation = '0';
        }
      } else if (buttonText == "✓") {
        console.log('close');
      } else if (buttonText == ".") {
        if (parseFloat(this.equation) == 0) {
          this.equation = this.equation + buttonText;
        } else {
          if (!this.equation.includes(".")) this.equation = this.equation + buttonText;
        }
      } else {
        if(this.initWorking){
          this.equation = buttonText
        }
        else if (parseFloat(this.equation) == 0 && !this.equation.includes(".")) {
          this.equation = buttonText
        } 
        else {
          this.equation = this.equation + buttonText;
        }
      }
      this.initWorking = false;
    },
    close() {
      this.onClose(this.equation, this.options);
    },
  },
  mounted() {
    this.equation = this.initnum.toString();
  },
};
</script>

<style scoped>
.row-key {
  display: flex;
  justify-content: stretch;
  width: 100%;
}
.button-key {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 50px;
  border: 1px solid white;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
}
.key-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 100%;
}
</style>
