<template>
  <div
    style="
      display: flex;
      align-items: stretch;
      height: 60px;
      width: 100%;
      padding: 5px;
      margin-bottom: 5px;
      background-color: #f3f3f3;
    "
  >
    <!-- Show Logo at mobile screen -->
    <div class="show-1024">
      <div class="icon-menu" @click="backhome">
        <fas icon="chevron-left"></fas>
      </div>
      <div style="padding: 0px 10px">
        <img width="50" :src="logo" />
      </div>
    </div>
    <!-- Title -->
    <div
      style="
        flex-grow: 1;
        padding-top: 5px;
        color: var(--main-color);
        font-size: 15px;
        font-weight: bold;
      "
    >
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    logo: {
      typeof: String,
      required: true,
    },
    title: {
      typeof: String,
      required: true,
    },
  },

  data() {
    return {
      appjs: window.appjs,
    };
  },
  computed: {
    getlang() {
      return this.appjs.getlang();
    },
  },

  methods: {
    backhome(){
       this.$router.push({path:'/home'});
    }
  },

  mounted() {},
};
</script>


<style scoped>
.icon-menu {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--main-color);
  font-size: 22px;
  padding: 0px 10px;
  cursor: pointer;
}
.icon-menu:hover {
  color: var(--sub-color);
}

.show-1024 {
  display: none;
}

.hide-1024 {
  display: flex;
}
@media (max-width: 1024px) {
  .show-1024 {
    display: flex;
    flex-grow: 0;
    justify-content: space-evenly;
  }
  .hide-1024 {
    display: none;
  }
}
</style>