import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {settings} from "./assets/config.js"
// import {settings} from "https://thmeypos.com/config/config.js"

import $ from 'jquery'
window.$ = window.JQuery = $
import "popper.js";
import "bootstrap";

import "bootstrap/dist/css/bootstrap.css";

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)


let host = location.hostname;

host = host.replace("www.", "");
host = host.replace(".", "");
let checkDb = Object.keys(settings.dbconfig).includes(host);
if(checkDb){
    settings.dbusing = settings.dbconfig[host];
}
import ("./assets/stores/"+settings.dbusing.dbname+".css");
import "./assets/app.css";
import appjs from "./assets/app";


//check domain to set database
// let host = location.hostname;//.replace(/./g,'');
// host = host.replace(".", "");
// let checkDb = Object.keys(settings.dbconfig).includes(host);
// if(checkDb){
//     settings.dbusing = settings.dbconfig[host];
// }
//localStorage.setItem('company_config', JSON.stringify(settings.dbusing));

window.appjs = appjs
const app = createApp(App);
app.config.globalProperties.config = settings;
app.use(store).use(router).use($).component('fas', FontAwesomeIcon).mount('#app');
