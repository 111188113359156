<template>
  <!-- template for the modal component -->
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" style="position: relative; padding:5px;">
          <div @click="$emit('close')" style="
          display:flex; 
          justify-content: center;
          align-items: center;
          cursor: pointer;
          color: #fff;
          position: absolute; right:0; top:-10px; width:30px; height:30px; background-color:red">
              <fas icon="times"></fas>
          </div>
          <!-- content -->
          <div style="display:flex; flex-direction:column; ">
            <div
              style="width: 100%; height:100px; background-color: #fff; border-radius: 7px 7px 0px 0px;"
            >
              <div
                class="item-image"
                id="test"
                v-bind:style="{
                  'background-image':
                    'url('+filepath+'/resources/filelibrary/' +
                    (product.imginfo != null && product.imginfo != ''
                      ? JSON.parse(product.imginfo).file_name
                      : '/system/nopic.png') +
                    ')',
                }"
              ></div>
            </div>
            <!--  -->
            <div
              style="
                            display:flex; 
                            flex-direction:column; 
                            padding: 5px 5px;
                            "
            >
              <!--  -->
              <div class="title-container">
                <div class="product-title">
                  <span v-html="appjs.decoder(product.title)"></span>
                </div>
              </div>

              <div style="height:15px; font-size:11px">
                {{ product.priceformat }}/{{ product.unit }}
              </div>
            </div>
            <!--  -->
            <div v-if="sizes.length>0" class="bar">
              <div class="list-container">
                <div v-for="(size, index) in sizes" :key="size.id" v-bind:class= "(size.selected)?'item active':'item'" 
                @click="selectSize(index)">
                  {{size.name}}
                  </div>
              </div>
            </div>

             <!--  -->
            <div class="bar">
              <div class="list-container">
                <div v-for="(color, index) in colors" :key="color.id" v-bind:class= "(color.selected)?'item active':'item'"
                @click="selectColor(index)"
                >
                  {{color.name}}
                  </div>
              </div>
            </div>


            <!-- footer -->
            <div style="
          display:flex; 
          justify-content: center;
          align-items: center;
          height:35px;
          cursor: pointer;
          color: #fff;
          background-color:var(--main-color);
          border-radius:5px;
          "
          @click="addtocart"
          >
              Add
            </div>

          </div>


          <!-- 
            <button class="modal-default-button" @click="$emit('close')">
                OK
              </button>
           -->
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "ModalSizeColor",
  props: {
    product: {
      typeof: Object,
      required: true,
    },
  },
  data() {
    return {
      appjs: window.appjs,
      initdata: JSON.parse(localStorage.getItem("initdata")),
      sysconfig: JSON.parse(localStorage.getItem("sysconfig")),
      currencyinfo: JSON.parse(localStorage.getItem("currencyinfo")),
      all_size: [],
      all_color:[],
      filepath:'',
      sizes: [],
      colors: [],
      size_select:1,
      color_select:1,
    };
  },
  methods:{
    selectSize(index){
      //console.log(this.sizes[index]);
      for (let ind in this.sizes) {
        this.sizes[ind].selected = false;
      }
      this.sizes[index].selected = true;
      this.size_select = this.sizes[index].id;
    },

    selectColor(index){
      //console.log(this.colors[index]);
      for (let ind in this.colors) {
        this.colors[ind].selected = false;
      }
      this.colors[index].selected = true;
      this.color_select = this.colors[index].id;
    },
    addtocart(){
      this.appjs.playSound(this.filepath+'/resources/sound/button-35.mp3')
      let item = this.product;
      let product = this.appjs.initProduct(item, 1,-1, this.all_size, this.all_color);
      let cartItem = this.appjs.initialCartItem(product, this.size_select, this.color_select, this.currencyinfo, this.sysconfig);
      this.appjs.addCartItemlToList(cartItem, this.currencyinfo); 
          
    },

  },
  mounted() {
    this.filepath = this.$store.state.apiurl;
    this.all_size = this.initdata.allsizes;
    this.all_color = this.initdata.allcolors;
    
    this.sizes = this.appjs.initProductColor(this.product.sizes, this.all_size)
    ///
    this.colors = this.appjs.initProductColor(this.product.colors, this.all_color)
    
  
    window.$(document).ready(function() {
      //window.appjs.scrollTitle('title-container', 'product-title');
    });
  },
};
</script>

<style scoped>
.item-image {
  border-radius: 7px 7px 0px 0px;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
}

.title-container {
  position: relative;
  overflow-x: hidden;
  height: 25px;
  font-size: 14px;
}
.product-title {
  position: absolute;
  white-space: nowrap;
  overflow-x: auto;
  transform: translateX(0px);
  transition: transform 2s;
}

.bar {
  top: 0;
  /* background-color: rgb(201, 21, 21); */
  overflow: hidden;
  width: inherit;
  padding: 10px 0px;
  border-top: 1px solid #ddd;
}

.list-container {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: flex-start; */
  align-content: flex-start;
  margin: 0px 5px;
  padding-bottom: 5px;
  width: auto;
}

.list-container .item:hover,
.list-container .item:active,
.list-container .item:visited,
.list-container .item:focus,
.list-container .active {
  background: #005b7a;
  color: #fff !important;
}

.list-container .item {
  /* flex: 0 0 auto; */
  border: 1px solid #005b7a;
  color: #005b7a;
  margin: 3px;
  border-radius: 5px;
  padding: 3px 15px;
  text-align: center;
  transition: 0.5s all;
  cursor: pointer;
  font-size: 15px;
  /* -webkit-box-shadow: 0 4px 10px rgb(10 36 99 / 14%);
    box-shadow: 0 4px 10px rgb(10 36 99 / 14%); */
}
</style>
