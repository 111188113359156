<template>
  <div style="display: flex; justify-content: stretch; height:100%">
    <div
      class="main-button"
      style="flex-grow: 1; margin: 0px 0px 0px 0px"
      @click="openOrder"
    >
    <div class="round">
 <div id="cta">
            <span class="arrow primera next bounceAlpha"></span>
            <span class="arrow segunda next bounceAlpha"></span>
        </div>
    </div>

      <div style="margin-left:30px">
        {{ getlang.store.order }}
      </div>
      <div>
        {{ lastcalculate.subtotal }} /
        {{ lastcalculate.subtotal_native }}
      </div>
    </div>
  </div>

  <order v-if="showOrder" @close="showOrder = false"></order>

</template>

<script>
// import the styling for the toast
import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";
import Order from "@/components/Order.vue";
export default {
  components: {
    'order' : Order
 
  },

  data() {
    return {
      appjs: window.appjs,
      //
      showOrder:false,
    };
  },

   methods: {
     openOrder(){
       if(this.cartList.length==0){
         createToast(
                    { description: this.getlang.store.noitem },
                    { type: "success" }
                  );
       }
       else{
         this.showOrder = true;
       }
       
     },

    
   
  },


  // 
   computed: {
    getlang() {
      return this.appjs.getlang();
      //return this.$store.state.getlang;
    },

    cartList() {
      return this.$store.state.cartList;
    },

    lastcalculate() {
      return this.$store.state.lastCalculate;
    },

    pageinfo() {
      return JSON.parse(localStorage.getItem("pageinfo"));
    },
  },
  // 
};
</script>


<style scoped>
.main-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 5px;
  border-radius: 5px;
  background-color: var(--main-color);
  color: #fff;
  cursor: pointer;
}
.main-button:hover {
  color: var(--sub-color);
}

.sub-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: var(--sub-color);
  cursor: pointer;
}
.sub-button:hover {
  color: var(--main-color);
}

/*  */
.round {
    position: absolute;
    /* border: 2px solid #fff; */
    width: 40px;
    height: 100%;
    border-radius: 100%;
    left:5px;
    
}


#cta{
    width:100%; cursor: pointer; position: absolute;
}

#cta .arrow{left: 30%;}
.arrow {position: absolute; bottom: 0;  margin-left:0px; width: 12px; height: 12px; background-size: contain; top:15px;}
.segunda{margin-left: 8px;}
.next {
	background-image: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTEyIDUxMiI+PHN0eWxlPi5zdDB7ZmlsbDojZmZmfTwvc3R5bGU+PHBhdGggY2xhc3M9InN0MCIgZD0iTTMxOS4xIDIxN2MyMC4yIDIwLjIgMTkuOSA1My4yLS42IDczLjdzLTUzLjUgMjAuOC03My43LjZsLTE5MC0xOTBjLTIwLjEtMjAuMi0xOS44LTUzLjIuNy03My43UzEwOSA2LjggMTI5LjEgMjdsMTkwIDE5MHoiLz48cGF0aCBjbGFzcz0ic3QwIiBkPSJNMzE5LjEgMjkwLjVjMjAuMi0yMC4yIDE5LjktNTMuMi0uNi03My43cy01My41LTIwLjgtNzMuNy0uNmwtMTkwIDE5MGMtMjAuMiAyMC4yLTE5LjkgNTMuMi42IDczLjdzNTMuNSAyMC44IDczLjcuNmwxOTAtMTkweiIvPjwvc3ZnPg==);
}

@keyframes bounceAlpha {
  0% {opacity: 1; transform: translateX(0px) scale(1);}
  25%{opacity: 0; transform:translateX(10px) scale(0.9);}
  26%{opacity: 0; transform:translateX(-10px) scale(0.9);}
  55% {opacity: 1; transform: translateX(0px) scale(1);}
}

.bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration:1.4s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
}

.arrow.primera.bounceAlpha {
    animation-name: bounceAlpha;
    animation-duration:1.4s;
    animation-delay:0.2s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
}

.round:hover .arrow{
    animation-name: bounceAlpha;
    animation-duration:1.4s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
}
.round:hover .arrow.primera{
    animation-name: bounceAlpha;
    animation-duration:1.4s;
    animation-delay:0.2s;
    animation-iteration-count:infinite;
    animation-timing-function:linear;
}

</style>