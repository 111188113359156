<template>
    <div class="this-modal-mask" >
    <div class="this-modal-wrapper">
        <div style="position:absolute; z-index:999; top:-39px; left:0px; background-color:#f7f5f5; padding:15px; display:flex; align-items:center; height:40px;" @click="$emit('close')">
              <fas style="font-size:25px" icon="times"></fas>
          </div>
      <div class="this-modal-container">
          
        <!--  -->
        <menu-left></menu-left>
        <!--  -->
      </div>
    </div>
  </div>

  
</template>

<script>
import Menu from "@/components/Menu.vue";
export default({
  name: 'ModalMenu',
    components: {
    "menu-left": Menu,
  },
   computed: {
    getlang() {
      return this.$store.state.getlang;
    },
  },
  methods:{

  }
})
</script>



<style scoped>
.this-modal-mask {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.5s ease;
}
.this-modal-wrapper {
  margin-top: 55px;
  margin-right: 5px;
  display: flex;
  align-items: stretch;
  /* background-color: #f1f1f1; */
  height: 93vh;
  position: relative;
  z-index: 999 !important;
}
.this-modal-container {
  position: absolute;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 95%;
  padding:0px 10px;
  background-color: #f7f5f5;
  box-shadow:         0px 2px 5px rgba(50, 50, 50, 0.75);
  transition: transform .5s;
  
}

.toleft{
    transform: translateX(-100%);
    left: 0;
}
.toright{
    left: 0;
    transform: translateX(0);
}

.menu-container{
  display:flex; padding:10px 0px;
  cursor: pointer;
}
.menu-icon{margin:0px 20px 0px 10px; color:#aaa}
.menu-title{
  color: var(--main-color);
}

.menu-container:hover .menu-title{
  color: var(--sub-color);
}
</style>