import router from '@/router'
import store from '@/store'
import axios from "axios";




let appJs = {
    isNum(val) {
        return !isNaN(val)
    },
    getlang() {
        let translate = localStorage.getItem('translate');
        if (translate == null) {
            router.replace({ path: '/' });
        } else {
            return JSON.parse(translate);
        }

    },

    currentLang() {
        let lang = localStorage.getItem('lang');
        let langKey = 'en';
        if (lang == null) {
            localStorage.setItem('lang', langKey);
        } else {
            langKey = lang;
        }
        return langKey;
    },

    domDecoder(str) {
        let parser = new DOMParser();
        let dom = parser.parseFromString('<!doctype html><body>' + str, 'text/html');
        return dom.body.textContent;
    },
    decoder(str) {
        let textArea = document.createElement('textarea');
        textArea.innerHTML = str;
        return textArea.value;
    },

    scrollTitle(title_container, product_title) {
        let $ = window.$;
        $(document).delegate("." + title_container, "mouseover", function () {
            var title = $(this).find('.' + product_title);
            var canScroll = parseInt(title.width()) - parseInt($(this).width());
            if (canScroll < 0) { canScroll = 0; }
            var moveStr = 'translateX(' + (canScroll * -1) + 'px)';
            title.css("transform", moveStr);
        });

        $(document).delegate("." + title_container, "mouseout", function () {
            var title = $(this).find('.' + product_title);
            title.css("transform", 'translateX(0px)');
        });
    },



    //////// start cart ///////
    containsKey(obj, key) {
        return Object.keys(obj).includes(key);
    },

    roundNumberUp(num, increment) {
        return Math.ceil(num / increment) * increment;
    },

    roundNumberDown(num, increment) {
        return Math.floor(num / increment) * increment;
    },
    formatMoney(amount, currencyinfo, withsymbol = true) {

        //currencyinfo => this is a global variable...is decleare at INDEX Blade;
        //let currency = "USD";
        let symbol = "$";
        let decimalCount = 2;
        let decimal = ".";
        let thousands = ",";
        let position = 1;

        if (currencyinfo) {

            //currency = currencyinfo.currency;
            symbol = currencyinfo.symbol;
            decimalCount = currencyinfo.numberdecimal;
            decimal = currencyinfo.decimalseparator;
            thousands = currencyinfo.thousandseparator;
            position = currencyinfo.position;
        }
        try {

            decimalCount = Math.abs(decimalCount);
            decimalCount = isNaN(decimalCount) ? 2 : decimalCount;
            //console.log(amount.format(2,decimalCount));
            const negativeSign = amount < 0 ? "-" : "";
            let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();

            let j = (i.length > 3) ? i.length % 3 : 0;

            let format = negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");

            if (withsymbol == false) return format;

            switch (position) {
                case 1:
                    return symbol + format;
                //break;
                case 2:
                    return format + symbol;
                //break;
                case 3:
                    return symbol + ' ' + format;
                //break;

                case 4:
                    return format + ' ' + symbol;
                //break;

                default:
                    return format;
            }

        } catch (e) {
            console.log(e)
        }
    },

    calAmount(unitprice, qty, discount, discounttype, vat) {
        let caldiscount, calvat = 0;
        let amount = 0;
        if (discounttype == -1) {
            caldiscount = unitprice - discount;
        } else {
            caldiscount = unitprice - ((unitprice * discount) / 100);
        }

        amount = (caldiscount * qty);
        calvat = (amount * vat) / 100;
        amount = amount + calvat;
        amount = parseFloat(amount.toFixed(2));
        return amount;
    },

    totalDiscount($pricing, $qty, $discount, currencyinfo) {
        let $amount = 0;
        $discount = parseFloat($discount);
        if ($discount < 0) {
            $discount = Math.abs($discount);
            $amount = $discount * $qty;
        }
        else {
            $amount = ($pricing * $discount) / 100;
            $amount = $amount * $qty;
        }
        return { 'noformat': $amount, 'format': this.formatMoney($amount, currencyinfo) };
    },

    billSummary($subtotal, $maindiscount, $maindiscounttype, $mainvat, $rec, $rec_native, $cartcount, currencyinfo) {
        let $total = 0;
        let $discount = '';
        if ($maindiscounttype == -1) {
            $total = $subtotal - $maindiscount;
            $discount = this.formatMoney(Math.abs($maindiscount), currencyinfo);
        } else {
            $total = $subtotal - (($subtotal * $maindiscount) / 100);
            $discount = this.formatMoney(Math.abs((($subtotal * $maindiscount) / 100)), currencyinfo);
        }

        let $grandtotal = $total + (($total * $mainvat) / 100);
        let roundup = 1.0;
        //let rounddown = 1.0;
        let rateoutuse = 1.0;
        let rateinuse = 1.0;

        if (currencyinfo) {

            roundup = parseFloat(currencyinfo['roundup'].toString());
            //rounddown = parseFloat(currencyinfo['rounddown'].toString());
            rateoutuse = parseFloat(currencyinfo['rateoutuse'].toString());
            rateinuse = parseFloat(currencyinfo['rateinuse'].toString());
        }


        let $nativesubtotal = parseFloat($subtotal * rateoutuse);
        //$nativesubtotal = this.roundNumberUp($nativesubtotal, roundup);

        $subtotal = this.formatMoney(parseFloat($subtotal), currencyinfo);
        let subcurrency = '';
        if (currencyinfo != null) {
            subcurrency = currencyinfo['subcurrency']['symbol'];
        }

        $nativesubtotal = subcurrency + this.formatMoney($nativesubtotal, currencyinfo, false);

        let $total_format = this.formatMoney($total, currencyinfo);
        let $total_native = $total * rateoutuse;
        $total_native = this.roundNumberUp($total_native, roundup);
        $total_native = subcurrency + this.formatMoney($total_native, currencyinfo, false);

        let $grandtotal_format = this.formatMoney($grandtotal, currencyinfo);
        let $grandtotal_native = $grandtotal * rateoutuse;
        //$grandtotal_native = this.roundNumberUp($grandtotal_native, roundup);
        let $grandtotal_native_format = subcurrency + this.formatMoney($grandtotal_native, currencyinfo, false);


        $rec = parseFloat($rec.toString());
        if ($rec == '' || $rec == null) $rec = 0;

        $rec_native = parseFloat($rec_native.toString());
        if ($rec_native == '' || $rec_native == null) $rec_native = 0;

        let $native_convert;
        let $total_rec;
        $native_convert = $rec_native / rateoutuse;
        $total_rec = $rec + $native_convert;

        let $change;
        let $change_lb, $change_native_lb = '';
        let $change_native;
        $change = $total_rec - $grandtotal;
        $change_native = 0;

        if ($total_rec >= $grandtotal) {
            if ($rec == 0) {

                $change_native = $rec_native - $grandtotal_native;
                if ($change_native == 0) {
                    $change = 0;
                }
            } else if ($change == 0 && $rec_native == 0) {

                $change_native = 0;

            } else {

                $change_native = $change * rateinuse;
                //$change_native = this.roundNumberDown($change_native, roundup);

            }

            $change_lb = this.formatMoney(parseFloat($change.toString()), currencyinfo);
            $change_native_lb = subcurrency + this.formatMoney($change_native, currencyinfo, false);

        } else {

            $change_lb = this.formatMoney(0, currencyinfo);
            $change_native_lb = subcurrency + this.formatMoney(0, currencyinfo, false);

        }

        let summary = {
            cartcount: $cartcount,
            subtotal: $subtotal,
            subtotal_native: $nativesubtotal,
            discount: $discount,
            total: $total_format,
            total_native: $total_native,
            grandtotal: $grandtotal_format,
            grandtotal_native: $grandtotal_native_format,
            rc: $rec,
            rc_native: $rec_native,
            rc_format: this.formatMoney($rec, currencyinfo),
            rc_nativeformat: subcurrency + this.formatMoney($rec_native, currencyinfo, false),
            change: $change_lb + '',
            change_native: $change_native_lb + '',
            grandtotal_noformat: $grandtotal,
            grandtotal_native_noformat: $grandtotal_native
        };


        return summary;
    },

    calculator(currencyInfo) {
        let subtotal = 0;
        let amount = 0;
        let cartCount = 0;
        if (store.state.cartList.length > 0) {
            cartCount = this.sumBy(store.state.cartList);
            store.state.cartList.forEach(item => {
                // cartCount = parseFloat(cartCount) + parseFloat(item.subqty);
                amount = this.calAmount(
                    item.unitprice,
                    parseFloat(item.subqty),
                    parseFloat(item.subdiscount),
                    item.subdiscounttype,
                    item.subvat);
                item.amount = parseFloat(amount);
                item.amount_format = this.formatMoney(amount, currencyInfo);
                subtotal = subtotal + item.amount;
            });

        }
        //billSummary($subtotal, $maindiscount, $maindiscounttype, $mainvat, $rec, $rec_native, currencyinfo)
        let mainDiscount = 0;
        let mainDiscountType = 0;
        let mainVat = 0;
        let payAmountUsd = 0;
        let payAmountNative = 0;

        if (store.state.mainpos['maindiscount']) {
            mainDiscount = parseFloat(store.state.mainpos['maindiscount']);
        }


        if (store.state.mainpos['maindiscounttype']) {
            mainDiscountType = parseFloat(store.state.mainpos['maindiscounttype']);
            if (mainDiscountType == 0 || mainDiscountType.isBlank) {
                mainDiscountType = 1; //parseFloat(this.sysConfig['sysconfig']['df_dis']);
            }

        }

        if (store.state.mainpos['mainvat']) {
            mainVat = parseFloat(store.state.mainpos['mainvat']);
        }

        if (store.state.mainpos['pay_amountusd']) {
            payAmountUsd = parseFloat(store.state.mainpos['pay_amountusd']);
        }

        if (store.state.mainpos['pay_amountnative']) {
            payAmountNative = parseFloat(store.state.mainpos['pay_amountnative']);
        }
        if (cartCount > 0) {
            cartCount = parseFloat(cartCount).toFixed(2);
        }

        localStorage.setItem('cartlist', JSON.stringify(store.state.cartList));

        let billSummary =
            this.billSummary(
                subtotal,
                mainDiscount,
                mainDiscountType,
                mainVat,
                payAmountUsd,
                payAmountNative,
                cartCount,
                currencyInfo);
        store.state.lastCalculate = billSummary;
    },

    sumBy(items) {
        return items.reduce(function (total, item) {

            return parseFloat(total) + parseFloat(item.subqty);
        }, 0);
    },

    initProductSize(sizes, all_size) {
        let sizes_item = [];
        if (sizes.length > 0) {
            let arr_size = sizes.split(",");
            if (arr_size.length > 0) {
                for (let ind in arr_size) {
                    let sizeid = arr_size[ind];
                    sizes_item.push({
                        id: sizeid,
                        name: all_size[sizeid],
                        selected: false,
                    });
                }

                sizes_item[0].selected = true;

            }
        }

        return sizes_item;
    },

    initProductColor(colors, all_color) {
        let colors_item = [];
        if (colors.length > 0) {
            let arr_color = colors.split(",");
            if (arr_color.length > 0) {
                for (let ind in arr_color) {
                    let colorid = arr_color[ind];
                    colors_item.push({
                        id: colorid,
                        color: '',
                        name: all_color[colorid],
                        selected: false,
                    });
                }

                colors_item[0].selected = true;

            }
        }

        return colors_item;
    },

    initProduct(item, qty, initPrice, all_size, all_color) {
        let unitprice = 0.0;
        if (initPrice >= 0) {
            unitprice = parseFloat(initPrice);
        }
        else {
            let ct_id = store.state.mainpos.ct_id;
            let pricing = JSON.parse(item.pricing);
            let dfpricing = pricing['dfpricing'];
            if (ct_id == 0) {
                unitprice = parseFloat(dfpricing);
            } else {
                unitprice = parseFloat(pricing[ct_id]);
                if (!unitprice || unitprice == 0 || unitprice == '')
                    unitprice = dfpricing;
            }
        }
        if (item.xtraprice == null || item.xtraprice == '') {
            item.xtraprice = '{}';
        }
        return {
            pid: item.id,
            image: item.imginfo,
            discount: item.discount,
            title: item.title,
            subqty: qty,
            pricing: item.pricing,
            priceformat: item.priceformat,
            unitprice: unitprice,
            unit: item.unit,
            pvat: item.pvat,
            sizes_item: this.initProductSize(item.sizes, all_size),
            colors_item: this.initProductColor(item.colors, all_color),
            xtraprice: JSON.parse(item.xtraprice)

        };
    },

    initialCartItem(product, sizeid, colorid, currencyInfo, sysconfig) {

        let sizeColor = sizeid + '-' + colorid;
        let xtraprice = product.xtraprice[sizeColor] ?? 0;
        let unitprice = product.unitprice + parseFloat(xtraprice);

        let unitpricelabel = this.formatMoney(unitprice, currencyInfo);
        let productToPos = {
            subid: '',
            subpd_id: product.pid,
            description: product.title,
            subsize: sizeid,
            subcolor: colorid,
            unitprice: unitprice,
            unitpricelabel: unitpricelabel,
            unit: product.unit,
            pricing: product.pricing,
            subdiscount: Math.abs(product.discount),
            subdiscounttype: product.discount == 0 ? sysconfig.df_dis : product.discount < 0 ? -1 : 1,
            subvat: product.pvat,
            amount: 0,
            amount_format: '',
            img: product.image,
            subqty: product.subqty,
            product: product,
        };

        return productToPos;
    },

    addCartItemlToList(item, currencyInfo) {
        //console.log(store.state.mainpos);
        let cartList = store.state.cartList;
        if (cartList.length == 0) {

            cartList.push(item);
        } else {
            let index = cartList.findIndex(element => {
                return (
                    element.subpd_id == item.subpd_id &&
                    element.subsize == item.subsize &&
                    element.subcolor == item.subcolor
                )
            });
            if (index == -1) {
                cartList.push(item);
            } else {
                let tmpItem = cartList[index];
                cartList.splice(index, 1);
                tmpItem.subqty += item.subqty;

                cartList.push(tmpItem);
            }
        }

        this.calculator(currencyInfo);
        //this.updateCart(currencyInfo);

        //var currencyInfo = this.sysConfig['currencyinfo'];
        //this.calculator(currencyInfo);
        //this.setCount(sum);
        //sharedPref.save('cartlist', posItemObj.encode(cartList));
        //sysconfig: JSON.parse(localStorage.getItem("sysconfig")),
    },

    removeCartItemFromList(index, currencyInfo) {
        store.state.cartList.splice(index, 1);
        this.calculator(currencyInfo);
    },

    emptyCartList(currencyInfo) {
        store.state.cartList = [];
        localStorage.removeItem('cartlist');
        this.calculator(currencyInfo);
    },

    setCartQuantity(index, quantity, currencyInfo) {
        quantity = parseFloat(quantity).toFixed(2);
        if (quantity <= 0) quantity = 1;
        let item = store.state.cartList[index];
        item.subqty = quantity;

        this.calculator(currencyInfo);

    },

    setCartDiscount(index, discount, currencyInfo) {
        discount = parseFloat(discount).toFixed(2);
        if (discount <= 0) discount = 0;
        let item = store.state.cartList[index];
        item.subdiscount = discount;
        this.calculator(currencyInfo);
    },

    setCartDiscountType(index, discountType, currencyInfo) {
        let item = store.state.cartList[index];
        item.subdiscounttype = discountType;
        this.calculator(currencyInfo);
    },

    setCartAmount(index, quantity, currencyInfo) {
        let chk_decimal = quantity % 1;
        if (chk_decimal == 0) {
            quantity = parseFloat(quantity).toFixed(0);
        } else {
            quantity = parseFloat(quantity).toFixed(4);
        }
        if (quantity <= 0) quantity = 1;
        let item = store.state.cartList[index];
        item.subqty = quantity;
        this.calculator(currencyInfo);

    },

    sizeColorCaption(sizes, size_id, colors, color_id) {
        // if (sizes[size_id] && colors[color_id]) {
        //     return sizes[size_id].name + '-' + colors[color_id].name;
        // } else if (sizes[size_id]) {
        //     return sizes[size_id].name;
        // } else if (colors[color_id]) {
        //     return colors[color_id].name;
        // } else return '';
        var select_size = sizes.find(item => item.id === size_id.toString());
        var select_color = colors.find(item => item.id === color_id.toString());

        if (select_size && select_color) {
            return select_size.name + '-' + select_color.name;
        } else if (select_size) {
            return select_size.name;
        } else if (select_color) {
            return select_color.name;
        } else return '';

    },

    updateCart(currencyInfo) {
        this.calculator(currencyInfo);
    },

    posSetInfo(mainCart, cartList) {

        let subid = [];
        let subpd_id = [];
        let description = [];
        let subsize = [];
        let subcolor = [];
        let subqty = [];
        let subunit = [];
        let unitprice = [];
        let subdiscount = [];
        let subdiscounttype = [];
        let subvat = [];
        //if(cartList){
        cartList.forEach(posItem => {
            subid.push(posItem.subid ?? '')
            subpd_id.push(posItem.subpd_id);
            description.push(posItem.description);
            subsize.push(posItem.subsize);
            subcolor.push(posItem.subcolor);
            subqty.push(posItem.subqty);
            subunit.push('');
            unitprice.push(posItem.unitprice);
            subdiscount.push(posItem.subdiscount);
            subdiscounttype.push(posItem.subdiscounttype);
            subvat.push(posItem.subvat);

        });
        subpd_id.push(0);
        //}

        var subTable = {
            'subid': subid,
            'subpd_id': subpd_id,
            'description': description,
            'subsize': subsize,
            'subcolor': subcolor,
            'subqty': subqty,
            'subunit': subunit,
            'unitprice': unitprice,
            'subdiscount': subdiscount,
            'subdiscounttype': subdiscounttype,
            'subvat': subvat,
        };
        //console.log(subTable);
        //store.state.mainpos['stage'] = -1;
        //let mainCart = store.state.mainpos;
        mainCart['stage'] = -1;
        if (mainCart.qoute_id != 0) {
            mainCart.qt_id = mainCart.qoute_id
        }

        const data = { ...mainCart, ...subTable };
        //const cartList = store.state.cartList;
        return { 'online': data, 'offline': { mainCart, cartList } };

    },

    setCustomer(customername, btnremove) {
        store.commit('SET_CUSTOMERNAME', customername);
        store.commit('SET_CUSTOMERREMOVE', btnremove);
    },

    cartFromHold(hold) {
        //console.log(hold);
        if (!hold.offline) {
            store.state.mainpos.title = hold.title;
            store.state.mainpos.qoute_id = hold.id;
            store.state.mainpos.cm_id = hold.cm_id;
            store.state.mainpos.customername = hold.latinname;
            store.state.mainpos.customerphone = hold.cphone;
            store.state.mainpos.inv_date = hold.inv_date;
            store.state.mainpos.due_date = hold.iss_date;
            this.setCustomer(hold.latinname, hold.cm_id == 0 ? false : true);
        }


    },

    async checkOnlineStatus() {
        try {
            const online = await fetch(store.state.apiurl + "/api/internet");
            //const online = await axios.get(store.state.apiurl + '/api/internet');
            return online.status >= 200 && online.status < 300; // either true or false
        } catch (err) {
            return false; // definitely offline
        }


    },

    updateProductDisplay(setting) {
        if (setting.numperpage < 1) {
            setting.numperpage = 1;
        }
        store.state.productSetting = setting;
        localStorage.setItem('productsetting', JSON.stringify(setting));
        //this.$store.dispatch("initProductSetting");
        // this.$router.replace({path:'/home'});
    },
    initCart(currencyInfo) {
        let cartlist = localStorage.getItem('cartlist');
        if (cartlist != null && cartlist.length > 3) {
            store.commit('SET_CARTLIST', JSON.parse(cartlist));
            this.calculator(currencyInfo);
        }
    },
    //   
    logout() {
        store.state.authentcation = false;
        store.commit('SET_AUTH', false);
        localStorage.removeItem('customerinfo');
        router.replace({ path: '/refresh' });
    },

    async getdb(apiurl, host) {
        await axios.get(apiurl + '/storeapi/dbinuse?host=' + host)
            .then(response => {
                console.log('aa', response);
            })
            .catch(error => console.log(error));
    },

    leftPad(number, targetLength) {
        var output = number + '';
        while (output.length < targetLength) {
            output = '0' + output;
        }
        return output;
    },

    convertDate(dateString){
        var p = dateString.split(/\D/g)
        return [p[2],p[1],p[0] ].join("-")
      },

    //

    playSound (sound) {
        if(sound) {
          var audio = new Audio(sound);
          audio.play();
        }
      },
      //


}

export default appJs