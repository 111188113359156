<template>
  <!-- template for the modal component -->
  <transition name="modal">
    <div class="modal-mask" style="text-align: left">
      <div class="modal-wrapper">
        <div class="modal-container" style="position: relative; padding: 5px">
          <div
            @click="$emit('close')"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              color: #fff;
              position: absolute;
              right: 0;
              top: -10px;
              width: 30px;
              height: 30px;
              background-color: red;
            "
          >
            <fas icon="times"></fas>
          </div>
          <!-- content -->
          <div style="font-size: 20px; color: var(--main-color)">
            <span>{{ getlang.store.forget }}</span>
          </div>

          <div class="modal-bar" style="padding: 10px 20px !important">
            <div v-if="showphone">
              <form @submit.prevent="sendOtp">
                <!-- @submit.prevent="sendOtp" -->
                <div class="form-group">
                  <label class="label-size" for="username">{{
                    getlang.store.phonenumber
                  }}</label>
                  <input
                    v-model="username"
                    type="text"
                    class="form-control input-no-focus"
                    id="username"
                    autocomplete="off"
                    @keyup="checkValidation"
                  />
                  <div class="invalid-feedback">
                    {{ getlang.store.needphone }}
                  </div>
                </div>

                <!-- recaptcha -->
                <div class="form-group">
                  <div id="recaptcha-container"></div>
                </div>

                <div
                  style="
                    display: flex;
                    justify-content: center;
                    padding: 20px 0px;
                  "
                >
                  <button
                    type="submit"
                    class="btn btn-outline-primary btn-block btn-black"
                    style="width: 50%"
                    id="sign-in-button"
                  >
                    <div class="btn-loading-container">
                      <div>{{ getlang.store.getotp }}</div>
                      <div v-if="!enableBtnStart" class="spinner-contaier">
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>
                    </div>
                  </button>
                </div>
              </form>
            </div>

            <!-- OTP -->
            <div v-if="showotp">
              <div class="label-size">OTP</div>
              <div style="padding: 10px !important">
                <div class="otp">
                  <otp-input :onCompletedOtp="completeOtp" />
                </div>
              </div>
            </div>

            <!-- loading to reset password  -->
            <div
              v-if="request_loading"
              style="display: flex; justify-content: center; padding: 20px 0px"
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </div>

            <!-- Show Success Message -->
            <div
              v-if="msg_success"
              class="label-size"
              style="
                display: flex;
                justify-content: center;
                padding: 20px 0px;
                color: green;
              "
            >
              {{ getlang.store.success_resetpwd }}
            </div>
            <!-- back to login -->
            <div
              class="label-size link"
              style="border-top: 1px solid #ddd; padding-top: 5px"
              @click="showlogin"
            >
              <fas icon="arrow-left"></fas>&nbsp; &nbsp;{{
                getlang.store.login
              }}
            </div>
          </div>

          <!-- 
            <button class="modal-default-button" @click="$emit('close')">
                OK
              </button>
           -->
        </div>
      </div>
    </div>
  </transition>
</template>


<script>
import axios from "axios";

import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";

import OtpInput from "@/components/OtpInput.vue";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
const firebaseConfig = {
  apiKey: "AIzaSyDgjVVke3r7JVvq5YzpuwpCM1Epbmvb1xk",
  authDomain: "ipos-store.firebaseapp.com",
  projectId: "ipos-store",
  storageBucket: "ipos-store.appspot.com",
  messagingSenderId: "818068006488",
  appId: "1:818068006488:web:882d633e1431bf0167cc2d",
  measurementId: "G-RQFDV3XL67",
};
initializeApp(firebaseConfig);
const auth = getAuth();
export default {
  components: {
    "otp-input": OtpInput,
  },
  props: {
    onLogin: Function,
  },
  data() {
    return {
      appjs: window.appjs,
      loadingIndicator: this.loadingIndicator,
      enableBtnStart: true,
      username: "",
      password: "",
      showPassword: false,

      //
      showphone: true,
      showotp: false,
      request_loading: false,
      msg_success: false,

      appVerifier: "",
      otp: "",
    };
  },

  computed: {
    getlang() {
      return this.appjs.getlang();
      //return this.$store.state.getlang;
    },
  },

  methods: {
    async btn_start_click() {
      let validation = true;
      if (this.username == "" || this.username == null) {
        window.$("#username").addClass("is-invalid");
        validation = false;
      }

      if (validation) {
        this.enableBtnStart = !this.enableBtnStart;
        await this.sendOtp();
        this.enableBtnStart = true;
      }
    },
    checkValidation() {
      if (this.username.length > 0) {
        window.$("#username").removeClass("is-invalid");
      }

      if (this.password.length > 0) {
        window.$("#password").removeClass("is-invalid");
      }
    },
    //
    showlogin() {
      this.onLogin();
    },

    //
    initReCaptcha() {
      // setTimeout(() => {
      //   window.recaptchaVerifier = new RecaptchaVerifier(
      //     "recaptcha-container",
      //     {
      //       size: "invisible",
      //     }
      //   );
      //   //
      //   this.appVerifier = window.recaptchaVerifier;
      // }, 1000);

      // window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {}, auth);
      //  window.recaptchaVerifier.render().then((widgetId) => {
      //   window.recaptchaWidgetId = widgetId;
      // });
      // this.appVerifier = window.recaptchaVerifier;

      window.recaptchaVerifier = new RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible",
        },
        auth
      );
      this.appVerifier = window.recaptchaVerifier;
    },
    //

    async sendOtp() {
      let phoneNumber = this.username;
      phoneNumber = phoneNumber.replace("+855", "");
      if (this.username.length == 9 || this.username == 10) {
        //
        this.enableBtnStart = !this.enableBtnStart;
        const langKey = this.appjs.currentLang();
        const shopname = this.config.dbusing.dbname;
        await axios
          .get(
            this.$store.state.apiurl +
              "/storeapi/" +
              shopname +
              "/checkphone?cphone=" +
              phoneNumber +
              "&lang=" +
              langKey
          )
          .then((response) => {
            if (response.data.status == true) {
              let that = this;
              let countryCode = "+855"; //Cambodia
              phoneNumber = countryCode + this.username;

              //start send sms
              signInWithPhoneNumber(auth, phoneNumber, this.appVerifier)
                .then(function (confirmationResult) {
                  that.showphone = false;
                  that.showotp = true;
                  window.confirmationResult = confirmationResult;
                  createToast(
                    { description: this.getlang.store.checksms },
                    { type: "success" }
                  );
                })
                .catch(function (error) {
                  console.log(error);
                  createToast(
                    { description: this.getlang.store.wrongphone },
                    { type: "danger" }
                  );
                });
            } else {
              createToast(
                { description: response.data.message },
                { type: "danger" }
              );
            }
          })
          .catch((error) => console.log(error));

          this.enableBtnStart = true;
      } else {
        createToast(
          { description: this.getlang.store.invalidphone },
          { type: "danger" }
        );
      }
    },
    //

    async completeOtp(code) {
      window.confirmationResult
        .confirm(code)
        .then(async (result) => {
          // User signed in successfully.
          await this.resetPassword(code);
          const user = result.user;
          console.log(user);
          // ...
        })
        .catch((error) => {
          createToast(
            { description: this.getlang.store.wrongotp },
            { type: "danger" }
          );
          console.log(error);
        });
    },
    //

    async resetPassword(code){
      /*=====================*/
          //show loading
          this.showotp = false;
          this.request_loading = true;

          const langKey = this.appjs.currentLang();
          let shopname = this.config.dbusing.dbname;

          let dataPost = {
            username: this.username,
            code: code,
            token: "!555524!",
            lang: langKey,
          };

          await axios
            .post(
              this.$store.state.apiurl + "/storeapi/" + shopname + "/resetpwd",
              dataPost,
              {
                headers: this.$store.state.headers,
              }
            )
            .then((response) => {
              if (response.status == 200 && response.data.success) {
                this.request_loading = false;
                this.msg_success = true;
              }
            })
            .catch((error) => {
              if (error.response) {
                //console.log(error.response.data.message);
                //console.log(error.response.status);
                //console.log(error.response.headers);
                if (error.response.status == 401) {
                  let message = error.response.data.message;
                  createToast({ description: message }, { type: "danger" });
                } else if (error.response.status == 500) {
                  createToast(
                    { description: "no internet" },
                    { type: "danger" }
                  );
                }
              } else {
                createToast(
                  { description: "something's wrong" },
                  { type: "danger" }
                );
              }
            });
          /*=====================*/
    }
  },
  created() {},
  mounted() {
    this.initReCaptcha();
  },
};
</script>

<style scoped>

.form-group {
  margin-bottom: 0px !important;
}

.otp {
  display: flex;
  justify-content: center;
  align-items: center;
  
}
</style>