<template>
  <div
    style="display: flex; border-bottom: 1px solid #ddd; padding-bottom: 10px"
  >
    <!-- no -->
    <div
      style="
        width: 15px;
        padding: 2px 3px 0px 0px;
        font-size: 10px;
        color: black;
      "
    >
      {{ index + 1 }}.
    </div>

    <!--  -->
    <div style="display: flex; flex-direction: column; flex-grow: 1">
      <!-- column 1 -->
      <div style="display: flex; padding: 0px 0px 3px 0px">
        <div style="flex-grow: 1">
          <div class="title-container">
            <div class="product-title">
              <span v-html="appjs.decoder(cartItem.description)"></span>
            </div>
          </div>
        </div>
        <div>
          <span class="btn-remove-color" @click="removeItem">
            <fas style="font-size:14px" icon="trash"></fas>
          </span>
        </div>
      </div>

      <!-- column 2 -->
      <div style="display: flex; justify-content: start">
        <!-- price and sizecolor -->
        <div style="display: flex; flex-direction: column; width: 100px; text-align:left">
          <div style="font-size: 11px; color: var(--main-color)">{{cartItem.unitpricelabel +'/'+ cartItem.unit}}</div>
          <div style="font-size: 11px; color: var(--main-color)">
            {{appjs.sizeColorCaption(cartItem.product.sizes_item, cartItem.subsize, cartItem.product.colors_item, cartItem.subcolor)}}
          </div>
         
        </div>
        <!-- qty -->
        <div class="input-group" style="width: 120px; height: 35px">
          <span class="input-group-text btn-new-color" @click="decrease">
            <fas icon="minus"></fas>
          </span>
          <input
            style="
              height: 35px;
              cursor: pointer;
              font-size: 13px;
              font-weight: bold;
              padding: 3px;
              text-align: center;
            "
            type="text"
            readonly
            class="form-control input-no-focus"
            :value="cartItem.subqty"
            @click="openNumPad(cartItem.subqty, {for:'qty'})"
          />

          <span class="input-group-text btn-new-color" @click="increase">
            <fas icon="plus"></fas>
          </span>
        </div>

        <!-- discount and amount -->
        <div style="flex-grow: 1; display: flex; flex-direction: column">
         

          <!-- amount -->
          <div
            style="
              display: flex;
              justify-content: flex-end;
              padding: 5px 0px;
              font-size: 14px;
              font-weight: bold;
              color: var(--sub-color);
              cursor: pointer;
            
            "
        
          >
          <!--     @click="openNumPad(cartItem.amount, {for:'amount'})" -->
            {{cartItem.amount_format}}
          </div>
        </div>
      </div>
    </div>
  </div>

  <modal-numpad v-if="showNumpad" :initnum="initNum" :options="numpadOptions" :onClose="closeNumpad"/>
</template>

<script>

import NumPad from "@/components/ModalNumPad.vue";

export default {
  name: "CartItem",
  components: {
 
    "modal-numpad": NumPad,
  },
  props: {
    defaultCartItem: {
      typeof: Object,
      required: true,
    },
    index: {
      typeof: Number,
      required: true,
    },
  },
  data() {
    return {
      appjs: window.appjs,
      sysconfig: JSON.parse(localStorage.getItem("sysconfig")),
      currencyinfo: JSON.parse(localStorage.getItem("currencyinfo")),
      showNumpad: false,
      initNum: 0,
      numpadOptions: {for:'qty'},
      filepath: "",
    };
  },
  methods: {
    increase() {
      this.cartItem.subqty = parseFloat(this.cartItem.subqty);
      
      if (this.cartItem.subqty >= 1) {
        this.cartItem.subqty += 1;
      } else if (this.cartItem.subqty <= 1) {
        this.cartItem.subqty += 0.1;
      }
      this.appjs.setCartQuantity(this.index, this.cartItem.subqty, this.currencyinfo);
    },
    decrease() {
      this.cartItem.subqty = parseFloat(this.cartItem.subqty);
      if (this.cartItem.subqty > 1) {
        this.cartItem.subqty -= 1;
      } else if (this.cartItem.subqty <= 1 && this.cartItem.subqty > 0.1) {
        this.cartItem.subqty -= 0.1;
      } else if (this.cartItem.subqty <= 0) {
        this.cartItem.subqty = 1;
      }
      this.appjs.setCartQuantity(this.index, this.cartItem.subqty, this.currencyinfo);

    },
    switchDiscount(val){
        let discountType = val=='no'?1:-1;
        this.appjs.setCartDiscountType(this.index, discountType, this.currencyInfo);
    },
    openNumPad(initNum, options){
      this.showNumpad = true;
      this.initNum = initNum;
      this.numpadOptions = options;
    },

    closeNumpad(val, options){
      this.showNumpad = false;
      if(options.for=='qty'){
        val = parseFloat(val);
        if(val==0) val=1;
        this.cartItem.subqty = val;
        this.appjs.setCartQuantity(this.index, this.cartItem.subqty, this.currencyinfo);
      }
      else if(options.for=='discount'){
        this.cartItem.subdiscount = parseFloat(val);
        this.appjs.setCartDiscount(this.index, this.cartItem.subdiscount, this.currencyinfo);

      }
      else if(options.for=='amount'){
        
        val = parseFloat(val);
        if(val>0){
          let unitprice = this.cartItem.unitprice;
          let newqty = val/unitprice;
          this.cartItem.subqty = newqty;
          this.appjs.setCartAmount(this.index, this.cartItem.subqty, this.currencyinfo);
        }
      }


    },

    removeItem(){
      this.appjs.playSound(this.filepath+'/resources/sound/button-20.mp3');
      this.appjs.removeCartItemFromList(this.index, this.currencyinfo);
    }


  },
  computed: {
    cartItem() {
      return this.defaultCartItem;
    },
  },
  mounted() {
    this.filepath = this.$store.state.apiurl;
      //console.log(this.sysconfig.df_dis);
  },
};
</script>

<style scoped>
.title-container {
  position: relative;
  overflow-x: hidden;
  height: 25px;
  font-size: 14px;
}
.product-title {
  color: var(--main-color);
  position: absolute;
  white-space: nowrap;
  overflow-x: auto;
  transform: translateX(0px);
  transition: transform 2s;
}
</style>
