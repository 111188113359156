<template>
    <div style="display:flex; justify-content: space-evenly">
        <div >
            <input class="otp-input" type="number" maxlength="1" v-model="otp1" id="1" @keyup="next($event)" />
        </div>

        <div >
            <input class="otp-input" type="number" maxlength="1" v-model="otp2" id="2" @keyup="next($event)" />
        </div>

        <div >
            <input class="otp-input" type="number" maxlength="1" v-model="otp3" id="3" @keyup="next($event)" />
        </div>

        <div >
            <input class="otp-input" type="number" maxlength="1" v-model="otp4" id="4" @keyup="next($event)" />
        </div>

        <div >
            <input class="otp-input" type="number" maxlength="1" v-model="otp5" id="5" @keyup="next($event)" />
        </div>

        <div >
            <input class="otp-input" type="number" maxlength="1" v-model="otp6" id="6" @keyup="next($event)" />
        </div>
        
    </div>
</template>

<script>
export default({
    props: {
    onCompletedOtp: Function,
  },
    data(){
        return {
            appjs: window.appjs,
            otp1:'',
            otp2:'',
            otp3:'',
            otp4:'',
            otp5:'',
            otp6:'',
        }
    },
   methods:{
       next: function(event) {
           const targetId = event.currentTarget.id;
            window.$('.otp-input').attr('type', 'number');
           if(this.appjs.isNum(event.key)){
               window.$('#'+targetId).val('').val(event.key);
                let next = parseInt(targetId)+1;
               if(next<=6 && event.currentTarget.value.length==1){
                   
                    window.$('#'+next).val('').focus();
                    //setTimeout(function(){ window.$('#'+targetId).attr('type', 'password'); }, 500);
               }
               if(next==7){
                   const code = this.otp1.toString()
                   +this.otp2.toString()
                   +this.otp3.toString()
                   +this.otp4.toString()
                   +this.otp5.toString()
                   +this.otp6.toString();
                   //setTimeout(function(){ window.$('.otp-input').attr('type', 'password'); }, 500);
                   this.onCompletedOtp(code);
               }
           }
           else if(event.which==8 && event.currentTarget.value.length==0){
               let pre = parseInt(targetId)-1;
               if(pre>=1){

                    window.$('#'+pre).select();

               }
              
           }
            //const targetId = event.currentTarget.id;
             // returns 'foo'
        }
   },
   mounted(){
       window.$('#1').focus();
   }
})
</script>


<style scoped>
    .otp-container{
        display: flex;

    }

    
.otp-input {
    width: 40px;
    height: 40px;
    padding: 5px;
    margin: 0 5px;
    font-size: 20px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    text-align: center;
    /* &.error {
      border: 1px solid red !important;
    } */
  }
  .otp-input::-webkit-inner-spin-button,
  .otp-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

    @media (max-width: 700px) {

    .otp-input {
      width: 38px;
    height: 38px;
    margin: 0 1px;
    }


    
  }
</style>