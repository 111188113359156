<template>
  <div style="display: flex; justify-content: space-between;width:100%">

      <div v-show="showcenter" class="icon-menu" @click="refresh">
        <div><fas class="icon-menu-icon-size" icon="redo"></fas></div>
        <div class="icon-menu-text-size">Refresh</div>
      </div>

      <div v-show="!showcenter" class="icon-menu" @click="back">
        <div><fas class="icon-menu-icon-size" icon="home"></fas></div>
        <div class="icon-menu-text-size">{{getlang.store.home}}</div>
      </div>

      <div class="icon-menu" @click="cart" style="position: relative">
        <div><fas class="icon-menu-icon-size" icon="shopping-cart"></fas></div>
        <div class="icon-menu-text-size">{{getlang.store.cart}}</div>
        <div class="badge">{{cartCount??0}}</div>
      </div>

      <div v-if="!logined" class="icon-menu" @click="$emit('showlogin')">
        <div><fas class="icon-menu-icon-size" icon="sign-in-alt"></fas></div>
        <div class="icon-menu-text-size">{{getlang.store.login}}</div>
      </div>

      <div v-if="!logined" class="icon-menu" @click="$emit('showregister')">
        <div><fas class="icon-menu-icon-size" icon="user-plus"></fas></div>
        <div class="icon-menu-text-size">{{getlang.store.register}}</div>
      </div>
      

      <div v-if="logined" class="icon-menu" @click="gotoOrdered">
        <div><fas class="icon-menu-icon-size" icon="clipboard-list"></fas></div>
        <div class="icon-menu-text-size">{{getlang.store.myorder}}</div>
      </div>

      <div v-if="logined" class="icon-menu" @click="appjs.logout">
        <div><fas class="icon-menu-icon-size" icon="sign-out-alt"></fas></div>
        <div class="icon-menu-text-size">{{getlang.store.logout}}</div>
      </div>


    </div>
</template>

<script>
export default {

    props: {
     showcenter: {
      typeof: Boolean,
      required: true,
    },
     cartCount: {
      typeof: Number,
      required: true,
    },
    onBack: Function,
    onCart: Function,

  },

  data() {
    return {
      appjs: window.appjs,
      logined: this.$store.state.authentcation,
    };
  },
computed: {
     getlang() {
        return this.appjs.getlang(); 
    }

  },

methods:{
  back(){
    this.onBack();
  },
  // 
  refresh(){
    this.$router.replace({ path: "/" });
  },
  // 

  cart(){
    this.onCart();
  },

  //
  gotoOrdered(){
    this.$router.push({path:'/ordered'});
  }
  //
},

  mounted(){
    this.logined = this.$store.state.authentcation;
  }
};
</script>


<style scoped>


.icon-menu {
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 3px;
  color: var(--main-color);
  cursor: pointer;
}
.icon-menu:hover {
  color: var(--sub-color);
}

</style>