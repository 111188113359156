<template>
  <!-- template for the modal component -->
  <transition name="modal">
    <div class="modal-mask" style="text-align: left">
      <div class="modal-wrapper">
        <div
          class="modal-container newwidth"
          style="position: relative; padding: 5px"
        >
          <div
            @click="$emit('close')"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              color: #fff;
              position: absolute;
              right: 0;
              top: -10px;
              width: 30px;
              height: 30px;
              background-color: red;
            "
          >
            <fas icon="times"></fas>
          </div>
          <!-- content -->

          <div style="font-size: 20px; color: var(--main-color)">
            <span>{{ getlang.store.order }}</span>
          </div>

          <div class="modal-bar" style="padding: 10px 20px !important">
            <div class="row" v-if="showform">
              <!-- Left -->
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="titlebar">{{ getlang.store.cartinfo }}</div>

                <div style="display: flex; flex-direction: column">
                  <div class="inforow">
                    <div>{{ getlang.store.itemincart }}</div>
                    <div>
                      <span class="data">{{ lastcalculate.cartcount }}</span>
                    </div>
                  </div>

                  <div class="inforow">
                    <div>
                      {{ getlang.store.total }} ({{ currencyinfo.symbol }})
                    </div>
                    <div>
                      <span class="data">{{ lastcalculate.grandtotal }}</span>
                    </div>
                  </div>

                  <div class="inforow">
                    <div>
                      {{ getlang.store.total }} ({{
                        currencyinfo.subcurrency.symbol
                      }})
                    </div>
                    <div>
                      <span class="data">{{
                        lastcalculate.grandtotal_native
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Right -->
              <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <div class="titlebar">{{ getlang.store.deliveryinfo }}</div>
                <div>
                  <form @submit.prevent="confirmOrder">
                    <!-- @submit.prevent="sendOtp" -->
                    <div class="form-group">
                      <label class="label-size" for="username"
                        >{{ getlang.store.phonenumber
                        }}<span class="required">*</span></label
                      >
                      <input
                        v-model="cphone"
                        type="text"
                        class="form-control input-no-focus"
                        id="cphone"
                        autocomplete="off"
                        @keyup="checkValidation"
                      />
                      <div class="invalid-feedback">
                        {{ getlang.store.needphone }}
                      </div>
                    </div>

                    <!-- real name -->
                    <div class="form-group">
                      <label class="label-size" for="realname"
                        >{{ getlang.store.realname
                        }}<span class="required">*</span></label
                      >
                      <input
                        v-model="realname"
                        type="text"
                        class="form-control input-no-focus"
                        id="realname"
                        autocomplete="off"
                        @keyup="checkValidation"
                      />
                      <div class="invalid-feedback">
                        {{ getlang.store.needname }}
                      </div>
                    </div>

                    <!-- address -->
                    <div class="form-group">
                      <label class="label-size" for="address"
                        >{{ getlang.store.address
                        }}<span class="required">*</span></label
                      >
                      <input
                        v-model="address"
                        type="text"
                        class="form-control input-no-focus"
                        id="address"
                        autocomplete="off"
                        @keyup="checkValidation"
                      />
                      <div class="invalid-feedback">
                        {{ getlang.store.need_address }}
                      </div>
                    </div>

                    <div
                      style="
                        display: flex;
                        justify-content: center;
                        padding: 20px 0px;
                      "
                    >
                      <button
                        type="submit"
                        class="btn btn-outline-primary btn-block btn-black"
                        style="width: 50%"
                        id="sign-in-button"
                      >
                        <div class="btn-loading-container">
                          <div>{{ getlang.store.confirmorder }}</div>
                          <div v-if="!enableBtnStart" class="spinner-contaier">
                            <span
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          </div>
                        </div>
                      </button>
                    </div>
                  </form>
                </div>
                <!-- below end right -->
              </div>
            </div>
            <!--above is end row -->

            <!-- If Maker Order Successfully -->
            <div
              v-if="showsuccess"
              style="display: flex; flex-direction: column"
            >
              <div
                style="
                  display: flex;
                  justify-content: center;
                  font-size: 18px;
                  color: green;
                  font-weight: bold;
                "
              >
                {{ getlang.store.thanks }}
              </div>
              <div
                style="
                  display: flex;
                  justify-content: center;
                  font-size: 15px;
                  color: green;
                "
              >
                {{ getlang.store.tanksorder }}
              </div>
              <div
                style="display: flex; flex;justify-content: space-evenly; padding:20px 30px"
              >
                <div class="link" @click="$emit('close')">
                  {{ getlang.store.shopmore }}
                </div>
                <div v-if="logined" class="link" @click="gotoOrdered">{{ getlang.store.vieworder }}</div>
              </div>
            </div>

            <!-- if Make Order Faile -->
            <div
              v-if="showfail"
              style="display: flex; justify-content: center; color: red"
            >
              Somethinkg is wrong!
            </div>
            <!--  -->
          </div>

          <!-- 
            <button class="modal-default-button" @click="$emit('close')">
                OK
              </button>
           -->
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";

import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";
//import OtpInput from "@/components/OtpInput.vue";

export default {
  components: {
    //"otp-input": OtpInput,
  },
  props: {
    onLogin: Function,
  },
  data() {
    return {
      appjs: window.appjs,
      currencyinfo: JSON.parse(localStorage.getItem("currencyinfo")),
      //form
      showform: true,
      showsuccess: false,
      showfail: false,
      enableBtnStart: true,
      showotp: false,
      formData: {},
      cphone: "",
      realname: "",
      address: "",
      referrer: "",
      //
      request_loading: false,
      msg_success: false,
      msg_fail: false,

      //
      logined: this.$store.state.authentcation,
      customer: {},
    };
  },
  methods: {
    checkValidation() {
      if (this.cphone.length > 0) {
        window.$("#cphone").removeClass("is-invalid");
      }

      if (this.address.length > 0) {
        window.$("#address").removeClass("is-invalid");
      }

      if (this.realname.length > 0) {
        window.$("#realname").removeClass("is-invalid");
      }
    },
    //

    async confirmOrder() {
      let validation = true;
      if (this.cphone == "" || this.cphone == null) {
        window.$("#cphone").addClass("is-invalid");
        validation = false;
      }
      if (this.realname == "" || this.realname == null) {
        window.$("#realname").addClass("is-invalid");
        validation = false;
      }

      if (this.address == "" || this.address == null) {
        window.$("#address").addClass("is-invalid");
        validation = false;
      }

      if (validation && this.$store.state.cartList.length > 0) {
        const internet = await this.appjs.checkOnlineStatus();
        if (internet) {
          const deliveryinfo = {
                      cphone: this.cphone,
                      realname: this.realname,
                      address: this.address,
                    }
          if (this.logined) {
            this.mainpos.cm_id = this.customer.cm_id;
            this.mainpos.title = this.realname + "-" + this.cphone;
          } else {
            this.mainpos.cm_id = 0;
            this.mainpos.title = this.realname + "-" + this.cphone;
          }
          this.mainpos.prv_note =  JSON.stringify(deliveryinfo);
          
          const data = this.appjs.posSetInfo(
            this.$store.state.mainpos,
            this.$store.state.cartList
          );
          let dataPost = data.online;
          const langKey = this.appjs.currentLang();
          let shopname = this.config.dbusing.dbname;

          dataPost.caddress = this.address;
          await axios
            .post(
              this.$store.state.apiurl +
                "/storeapi/" +
                shopname +
                "/makeorder?lang=" +
                langKey,
              dataPost,
              {
                headers: this.$store.state.headers,
              }
            )
            .then(async (response) => {
              if (response.status == 200) {
                if (response.data.act) {
                  //  response= {
                  //     'act':true,
                  //     'count':parseInt(response.data['count'].toString()),
                  //     'msg': response.data['passdata']['success']
                  //   };
                  this.appjs.emptyCartList(this.currencyinfo);
                  this.showform = false;
                  this.showsuccess = true;
                  localStorage.setItem(
                    "deliveryinfo",
                    JSON.stringify(deliveryinfo)
                  );
                  //also send telegram here

                  /*
                    https://api.telegram.org/bot2020116391:AAFMvag6GHxlR4J1VkyqjZ-IcBHRTM-nyyg/getMe
                    https://api.telegram.org/bot2020116391:AAFMvag6GHxlR4J1VkyqjZ-IcBHRTM-nyyg/getUpdates
                    https://api.telegram.org/bot2020116391:AAFMvag6GHxlR4J1VkyqjZ-IcBHRTM-nyyg/sendMessage?chat_id=414081385&text=hello

                    https://api.telegram.org/bot2020116391:AAFMvag6GHxlR4J1VkyqjZ-IcBHRTM-nyyg/sendMessage?chat_id=-509261422&text=hello
                  */
                 let text = `New Purchase Order
                 %0A Title: ${this.mainpos.title}
                 %0A Customer Name: ${this.realname}
                 %0A Phone: ${this.cphone}
                 %0A Address: ${this.address}
                 %0A Order No.: ${this.appjs.leftPad(response.data.passdata.id, 6)}
                 `;
                  await axios.get(
                    `https://api.telegram.org/bot${this.config.dbusing.telegram.token}/sendMessage?chat_id=${this.config.dbusing.telegram.chat_id}&parse_mode=HTML&text=${text}`
                   
                  );
                  //console.log(response.data);
                } else {
                  // response= {
                  //   'act':false,
                  //   'msg': response.data['errors']
                  //  };
                  this.showform = false;
                  this.showsuccess = false;
                  this.showfail = true;
                  createToast(
                    { description: response.data.errors },
                    { type: "danger" }
                  );
                }
              }
            })
            .catch((error) => {
              if (error.response) {
                if (error.response.status == 401) {
                  let message = error.response.data.message;
                  createToast({ description: message }, { type: "danger" });
                } else if (error.response.status == 500) {
                  createToast(
                    {
                      description: this.getlang.ccms.nointernet,
                    },
                    { type: "danger" }
                  );
                }
              } else {
                createToast(
                  { description: this.getlang.ccms.rqnvalid },
                  { type: "danger" }
                );
              }
            });
        }
      }
    },
    //
       gotoOrdered(){
    this.$router.push({path:'/ordered'});
  }
  //
  },
  computed: {
    getlang() {
      return this.appjs.getlang();
      //return this.$store.state.getlang;
    },

    lastcalculate() {
      return this.$store.state.lastCalculate;
    },

    mainpos() {
      return this.$store.state.mainpos;
    },
  },

  mounted() {
    const customerinfo = localStorage.getItem("customerinfo");
    if (customerinfo == null || customerinfo == "") {
      //this.welcometo = this.getlang.store.generalcustomer;
      const deliveryinfo = localStorage.getItem("deliveryinfo");
      if (deliveryinfo != null && deliveryinfo != "") {
        const delivery = JSON.parse(deliveryinfo);
        this.cphone = delivery.cphone;
        this.realname = delivery.realname;
        this.address = delivery.address;
      }
    } else {
      this.customer = JSON.parse(customerinfo);
      if (this.customer.nativename == "") {
        this.realname = this.customer.latinname;
      } else {
        this.realname =
          this.customer.nativename + "/" + this.customer.latinname;
      }
      this.cphone = this.customer.cphone;
      this.address = this.customer.caddress;
    }
    /////
  },
};
</script>

<style scoped>
.form-group {
  margin-bottom: 0px !important;
}
.required {
  color: red;
}

.newwidth {
  width: 60% !important;
}
.titlebar {
  color: var(--main-color);
  margin-bottom: 10px;
  border-bottom: 1px solid #ddd;
}
.inforow {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px dashed #ddd;
  padding-bottom: 5px;
  margin-bottom: 10px;
}
.data {
  font-size: 15px;
  color: var(--sub-color);
  font-weight: bold;
}

.link {
  border: 1px solid var(--main-color);
  border-radius: 5px;
  padding: 5px 15px;
  color: var(--main-color);
  cursor: pointer;
}

.link:hover {
  color: var(--sub-color);
}

@media (max-width: 700px) {
  .newwidth {
    width: 90% !important;
  }
}
</style>
