<template>
  <!-- template for the modal component -->
  <transition name="modal">
    <div class="modal-mask" style="text-align:left;">
      <div class="modal-wrapper">
        <div class="modal-container" style="position: relative; padding:5px;">
          <div @click="$emit('close')" style="
          display:flex; 
          justify-content: center;
          align-items: center;
          cursor: pointer;
          color: #fff;
          position: absolute; right:0; top:-10px; width:30px; height:30px; background-color:red">
              <fas icon="times"></fas>
          </div>
          <!-- content -->
            <div style="font-size:20px; color:var(--main-color)">
            <span>{{getlang.store.login}}</span>
          </div>

          <div class="modal-bar" style="padding:20px !important">
              <form>
            <div class="form-group">
              <label class="label-size" for="username">{{getlang.store.phonenumber}}</label>
                <input v-model="username" type="text" class="form-control input-no-focus" id="username" autocomplete="off" @keyup="checkValidation">
                <div class="invalid-feedback">
                  {{getlang.store.needphone}}
                </div>
                
              
            </div>

            <div class="form-group">
              <label class="label-size" for="password">{{getlang.store.password}}</label>
                
           

 <div class="input-group">
      <input v-model="password" v-bind:type="[showPassword ? 'text' : 'password']" class="form-control input-no-focus" id="password" @keyup="checkValidation">

    <div class="input-group-append">
        <span class="input-group-text" @click="showPassword = !showPassword" style="cursor:pointer">
    <fas :icon="['fas', showPassword?'eye':'eye-slash']" />
    </span>
    </div>

    <div class="invalid-feedback">
                  {{getlang.store.needpass}}
                </div>
  </div>
                
                
              
            </div>


            <div style="display:flex; justify-content: center; padding:20px 0px">
                
                    <button type="button" class="btn btn-outline-primary btn-block btn-black" style="width:50%" @click="btn_start_click">
                     <div class="btn-loading-container">
                       <div>{{getlang.store.login}}</div>
                       <div v-if="!enableBtnStart" class="spinner-contaier">
                         <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                       </div>
                     </div>
                    </button>
                
                
            </div>
          </form>

          <div class="label-size link" @click="showforget">
            {{getlang.store.forgetpwd}}
          </div>
          </div>


          <!-- 
            <button class="modal-default-button" @click="$emit('close')">
                OK
              </button>
           -->
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from 'axios';

import { createToast } from 'mosha-vue-toastify';
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css'
export default({
  props:{
    onForget: Function,
  },
 data(){
     return {
       appjs: window.appjs,
       loadingIndicator: this.loadingIndicator,
        enableBtnStart: true,
        username: '',
        password:'',
        showPassword: false,
      
      
    }
  },

  computed: {
    getlang() {
      return this.appjs.getlang();
      //return this.$store.state.getlang;
    },
  },

   methods:{
    async btn_start_click(){
      
      let validation = true;
      if(this.username=='' || this.username==null){
        window.$('#username').addClass('is-invalid');
        validation = false;
      }
      if(this.password=='' || this.password==null){
        window.$('#password').addClass('is-invalid');
        validation = false;
      }

      if(validation){
        
          this.enableBtnStart = !this.enableBtnStart
          let shopname = this.config.dbusing.dbname;

          let dataPost= {username:this.username, password:this.password}

          // var bodyFormData = new FormData();
          // bodyFormData.append('username', this.username);
          // bodyFormData.append('password', this.password);
         
          await axios.post(this.$store.state.apiurl + '/storeapi/'+shopname+'/login', dataPost,{
            headers: this.$store.state.headers
          }).then(response => {
              if (response.status == 200) {
            
                if(response.data.success){
                  this.$store.state.authentcation = true;
                  this.$store.commit('SET_AUTH', true);
                  localStorage.setItem('customerinfo',JSON.stringify(response.data.customerinfo));
                  this.$emit('close');
                   createToast(
                    { description: this.getlang.store.success_login },
                    { type: "success" }
                  );
                  this.$router.replace({path:'/refresh'});
                }else{
                  createToast({ description: 'bbb'}, {type:'danger'});
                }
              }

          })
            .catch(error =>{
              if (error.response) {
                //console.log(error.response.data.message);
                //console.log(error.response.status);
                //console.log(error.response.headers);
                if(error.response.status==401){
                  let message = error.response.data.message;
                  createToast({description: message}, {type:'danger'});
                }
                else if(error.response.status==500){
                  createToast({ description:'no internet'}, {type:'danger'});
                }
              }
              else{
                createToast({description: 'something\'s wrong'}, {type:'danger'});
              }
              
          });

          this.enableBtnStart = true;
      }
      
    },
    checkValidation(){
      if(this.username.length>0){
        window.$('#username').removeClass('is-invalid');
      }

      if(this.password.length>0){
        window.$('#password').removeClass('is-invalid');
      }
    },
    // 
    showforget(){
      this.onForget();
    }
  },
  mounted(){
    //console.log(this.config.dbusing.dbname);
  }

})
</script>


<style scoped>
.form-group {
  margin-bottom: 0px !important;
}
</style>
