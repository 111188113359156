<template>
  <div class="flex-container">
    <!-- Header -->
    <!-- <div style="display:flex; width:100%; background-color:red">
      header
    </div> -->
    <div class="flex-body">
      <!-- Left -->
      <div class="flex-side-left">
        <!-- logo -->
        <div style="display: flex; flex-direction: column">
          <div>
            <img width="90" :src="logo" />
          </div>
          <div>
            {{ companyname }}
          </div>
          <div
            style="
              display: flex;
              flex-grow: 1;
              flex-direction: column;
              width: 100%;
              margin-top: 10px;
            "
          >
            <div class="left-menu" @click="backhome">
              <fas icon="home"></fas>
              {{ getlang.store.home }}
            </div>
          </div>
        </div>
      </div>
      <!-- Center -->
      <div class="flex-side-center">
        <!-- HEADER -->
        <div style="display: flex width: 100%">
          <header-page
            :logo="logo"
            :title="getlang.store.myorder"
          ></header-page>
        </div>

        <!-- Table -->
        <div style="display: flex; flex-direction:column">
          <div class="table-responsive">
            <table class="table table-striped table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">{{ getlang.store.order_id }}</th>
                  <th scope="col">{{ getlang.store.date }}</th>
                  <th scope="col">{{ getlang.store.title }}</th>
                  <th scope="col">{{ getlang.store.status }}</th>
                  <th scope="col">{{ getlang.store.total }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in orderlist" :key="index">
                  <th scope="row">{{ index + 1 }}</th>
                  <td>{{ this.sysconfig.qt + appjs.leftPad(item.id, 6) }}</td>
                  <td>{{ appjs.convertDate(item.inv_date) }}</td>
                  <td>{{ item.title }}</td>
                  <td>
                    {{
                      item.stage == -1 ? getlang.store.pending : item.stagetitle
                    }}
                  </td>
                  <td style="text-align: right">
                    {{ appjs.formatMoney(item.gtotal, currencyinfo, true) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div v-if="orderlist.length==0" style="display:flex; justify-content:center; color:red">
            {{getlang.store.norecord}}
          </div>
        </div>
        <!-- end CENTER -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import HeaderPage from "@/components/HeaderPage.vue";
export default {
  setup() {},
  components: {
    "header-page": HeaderPage,
  },
  computed: {
    getlang() {
      return this.appjs.getlang();
      //return this.$store.state.getlang;
    },
    pageinfo() {
      return JSON.parse(localStorage.getItem("pageinfo"));
    },
  },
  data() {
    return {
      appjs: window.appjs,
      currencyinfo: JSON.parse(localStorage.getItem("currencyinfo")),
      sysconfig: JSON.parse(localStorage.getItem("sysconfig")),
      logo: "",
      contact: {},
      companyname: "",
      companyname_latin: "",
      logined: this.$store.state.authentcation,
      orderlist: {},
    };
  },

   methods: {
    backhome(){
       this.$router.push({path:'/home'});
    }
  },

  async mounted() {
    this.filepath = this.$store.state.apiurl;
    this.logo = this.filepath + "/resources/filelibrary/" + this.pageinfo.logo;
    this.contact = JSON.parse(this.pageinfo.contact);
    if (this.contact.latinname) {
      this.companyname = this.contact.latinname;
    } else {
      this.companyname = this.contact.nativename;
    }

    //
    if (this.logined) {
      const langKey = this.appjs.currentLang();
      let shopname = this.config.dbusing.dbname;
      const customerinfo = localStorage.getItem("customerinfo");
      if (customerinfo != null && customerinfo != "") {
        const customer = JSON.parse(customerinfo);
        await axios
          .get(
            this.$store.state.apiurl +
              "/storeapi/" +
              shopname +
              "/myorder?cm_id=" +
              customer.cm_id +
              "&lang=" +
              langKey
          )
          .then((response) => {
            if (response.status == 200) {
              if (response.data.status) {
                this.orderlist = response.data.data;
              }
            }
          })
          .catch((error) => console.log(error));
      }
    }
  },
};
</script>

<style scoped>
.left-menu {
  text-align: left;
  padding: 5px;
  color: white;
  background-color: var(--main-color);
  cursor: pointer;
}
.left-menu:hover{
   color: var(--sub-color);
  font-weight: bold;
}
</style>
