import { createStore } from 'vuex'
import axios from 'axios'
import { settings } from "@/assets/config.js"

var currentDate = new Date();
var day = currentDate.getDate();
var month = currentDate.getMonth() + 1;
var year = currentDate.getFullYear();
var today = year + "/" + month + "/" + day;

var aMonthAfter = currentDate; // Now
aMonthAfter.setDate(aMonthAfter.getDate() + 30); // Set now + 30 days as the new date
var day1 = aMonthAfter.getDate();
var month1 = aMonthAfter.getMonth() + 1;
var year1 = aMonthAfter.getFullYear();
var amonthlater = year1 + "/" + month1 + "/" + day1;

let headers = {
    'Content-type': 'application/json',
    'Accept': 'application/json'
};

let host = location.hostname; //.replace(/./g,'');
host = host.replace("www.", "");
host = host.replace(".", "");
///
// const xx = axios.get(settings.apiurl + '/storeapi/dbinuse?host='+host)
//           .then(response => {
//             console.log(response);
//           })
//           .catch(error => console.log(error));
///
let checkDb = Object.keys(settings.dbconfig).includes(host);
if (checkDb) {
    settings.dbusing = settings.dbconfig[host];
}

export default createStore({
    state: {
        authentcation: false,
        appTitle: 'i-POS',
        apiurl: settings.apiurl,
        dbinfo: settings.dbusing,
        users: [],
        getlang: null,
        headers: headers,
        initdata: null,
        customer: null,
        //for display customer name at CART
        customername: '',
        mainpos: {
            cm_id: 0,
            ct_id: 0,
            title: 'General POS',
            stage: '0',
            inv_date: today,
            due_date: amonthlater,
            fter_note: '',
            prv_note: '',
            sale_id: '',
            mainvat: 0.0,
            maindiscount: 0.0,
            maindiscounttype: 0,
            inv_cycle: '1',
            qoute_id: 0,
            trash: 'no',
            tags: '',
            pay_amountusd: 0.0,
            pay_amountnative: 0.0,
            pmethod_id: 0,
            customername: '',
            customerphone: '',
            referral: '',

        },
        cartList: [],
        lastCalculate: {},
        countHold: 0,
        today: today,
        amonthlater: amonthlater,
        getxOfflineHold: [],
        getxOfflineHoldProduct: [],
        offlineCart: [],
        showSetting: false,
        productSetting: {},
    },
    mutations: {
        SET_GETLANG(state, getlang) {
            state.getlang = getlang
        },

        INIT_DATA(state, initdata) {
            state.initdata = initdata
        },

        SET_PRODUCTSETTING(state, setting) {
            state.productSetting = setting
        },

        SET_CARTLIST(state, cartList) {
            state.cartList = cartList
        },

        SET_AUTH(state, bool) {
            state.authentcation = bool;
        }

    },
    actions: {
        async restApiGetLang({ commit }) {
            let lang = localStorage.getItem('lang');
            let langKey = this.state.dbinfo.dflang;
            if (lang == null) {
                localStorage.setItem('lang', langKey);
            } else {
                langKey = lang;
            }

            await axios.get(this.state.apiurl + '/storeapi/gettranslate?lang=' + langKey)
                .then(response => {
                    //console.log(response);
                    commit('SET_GETLANG', response.data)
                    localStorage.setItem('translate', JSON.stringify(response.data));
                })
                .catch(error => console.log(error));
        },

        async initData({ commit }) {
            let lang = localStorage.getItem('lang');
            let langKey = 'en';
            if (lang == null) {
                localStorage.setItem('lang', langKey);
            } else {
                langKey = lang;
            }

            let company_config = this.state.dbinfo.dbname;
            //console.log(this.state.apiurl + '/storeapi/' + company_config + '/restdata?all=true&lang=' + langKey);
            //localStorage.removeItem('initdata');
            await axios.get(this.state.apiurl + '/storeapi/' + company_config + '/restdata?all=true&lang=' + langKey)
                .then(response => {
                    //console.log( response.data);
                    // commit('SET_CUSTOMER', response.data.customer);
                    // localStorage.setItem('ctype', JSON.stringify(response.data.ctype));
                    // delete response.data['customer'];

                    commit('INIT_DATA', response.data);
                    localStorage.setItem('initdata', JSON.stringify(response.data));


                    // localStorage.setItem('counthold', response.data.counthold);
                    // commit('SET_COUNTHOLD', response.data.counthold);

                })
                .catch(error => console.log(error));

        },

        async syncData() {
            let company_config = this.state.dbinfo.dbname;
            let lang = localStorage.getItem('lang');
            let langKey = 'en';
            if (lang == null) {
                localStorage.setItem('lang', langKey);
            } else {
                langKey = lang;
            }
            await axios.get(this.state.apiurl + '/storeapi/' + company_config + '/dbconfig?lang=' + langKey)
                .then(response => {
                    if (response.data.success) {
                        //console.log(response.data);
                        localStorage.setItem('sysconfig', JSON.stringify(response.data.sysconfig));
                        localStorage.setItem('currencyinfo', JSON.stringify(response.data.currencyinfo));
                        localStorage.setItem('paymentmethod', JSON.stringify(response.data.paymentmethod));
                        localStorage.setItem('pageinfo', JSON.stringify(response.data.pageinfo));
                        localStorage.setItem('multilang', JSON.stringify(response.data.multilang));
                    }
                });
        },

        initProductSetting({ commit }) {
            let productsetting = localStorage.getItem("productsetting");
            let setting = {};
            if (productsetting !== '' && productsetting != null) {
                setting = JSON.parse(productsetting)
            } else {
                setting = {
                    numperpage: 18,
                    width: 260,
                    imagedisplay: true,
                    imgheight: 141,
                }
            }

            commit('SET_PRODUCTSETTING', setting);
        }

    },
    modules: {}
})