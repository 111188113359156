<template>
  <!-- template for the modal component -->
  <transition name="modal">
    <div class="modal-mask" style="text-align: left">
      <div class="modal-wrapper">
        <div class="modal-container" style="position: relative; padding: 5px">
          <div
            @click="$emit('close')"
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              color: #fff;
              position: absolute;
              right: 0;
              top: -10px;
              width: 30px;
              height: 30px;
              background-color: red;
            "
          >
            <fas icon="times"></fas>
          </div>
          <!-- content -->

          <div style="font-size: 20px; color: var(--main-color)">
            <span>{{ getlang.store.register }}</span>
          </div>

          <div class="modal-bar" style="padding: 10px 20px !important">
            <div v-if="showform">
              <form @submit.prevent="sendOtp">
                <!-- @submit.prevent="sendOtp" -->
                <div class="form-group">
                  <label class="label-size" for="username"
                    >{{ getlang.store.phonenumber
                    }}<span class="required">*</span></label
                  >
                  <input
                    v-model="cphone"
                    type="text"
                    class="form-control input-no-focus"
                    id="cphone"
                    autocomplete="off"
                    @keyup="checkValidation"
                  />
                  <div class="invalid-feedback">
                    {{ getlang.store.needphone }}
                  </div>
                </div>

                <!-- password -->
                <div class="form-group">
                  <label class="label-size" for="password"
                    >{{ getlang.store.password}}<span class="required">*</span></label
                  >

                  <div class="input-group">
                    <input
                      v-model="password"
                      v-bind:type="[showPassword ? 'text' : 'password']"
                      class="form-control input-no-focus"
                      id="password"
                      @keyup="checkValidation"
                    />

                    <div class="input-group-append">
                      <span
                        class="input-group-text"
                        @click="showPassword = !showPassword"
                        style="cursor: pointer"
                      >
                        <fas
                          :icon="['fas', showPassword ? 'eye' : 'eye-slash']"
                        />
                      </span>
                    </div>

                    <div class="invalid-feedback">
                      {{ pwdinvalid }}
                    </div>
                  </div>
                </div>

                <!-- real name -->
                <div class="form-group">
                  <label class="label-size" for="realname"
                    >{{ getlang.store.realname
                    }}<span class="required">*</span></label
                  >
                  <input
                    v-model="realname"
                    type="text"
                    class="form-control input-no-focus"
                    id="realname"
                    autocomplete="off"
                    @keyup="checkValidation"
                  />
                  <div class="invalid-feedback">
                    {{ getlang.store.needname }}
                  </div>
                </div>

                <!-- email -->
                <div class="form-group">
                  <label class="label-size" for="email">{{
                    getlang.store.email
                  }}</label>
                  <input
                    v-model="email"
                    type="text"
                    class="form-control input-no-focus"
                    id="email"
                    autocomplete="off"
                  />
                </div>

                <!-- address -->
                <div class="form-group">
                  <label class="label-size" for="address">{{
                    getlang.store.address
                  }}</label>
                  <input
                    v-model="address"
                    type="text"
                    class="form-control input-no-focus"
                    id="address"
                    autocomplete="off"
                  />
                </div>

                <!-- referrer -->
                <div class="form-group">
                  <label class="label-size" for="referrer">{{
                    getlang.store.referrer
                  }}</label>
                  <input
                    v-model="referrer"
                    type="text"
                    class="form-control input-no-focus"
                    id="referrer"
                    autocomplete="off"
                  />
                </div>

                <div
                  style="
                    display: flex;
                    justify-content: center;
                    padding: 20px 0px;
                  "
                >
                  <button
                    type="submit"
                    class="btn btn-outline-primary btn-block btn-black"
                    style="width: 50%"
                    id="sign-in-button"
                  >
                    <div class="btn-loading-container">
                      <div>{{ getlang.store.register }}</div>
                      <div v-if="!enableBtnStart" class="spinner-contaier">
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>
                    </div>
                  </button>
                </div>
              </form>
            </div>

            <!-- OTP -->
            <div v-if="showotp">
              <div class="label-size">OTP</div>
              <div style="padding: 10px !important">
                <div class="otp">
                  <otp-input :onCompletedOtp="completeOtp" />
                </div>
              </div>
            </div>
          </div>

          <!-- loading to reset password  -->
            <div
              v-if="request_loading"
              style="display: flex; justify-content: center; padding: 20px 0px"
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </div>

            <!-- Show Success Message -->
            <div
              v-if="msg_success"
              class="label-size"
              style="
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 10px 20px;
                color: green;
              "
            >
              <div>
                <fas icon='check-circle'></fas>
              &nbsp;&nbsp;
              {{ getlang.store.success_register }}
              </div>
              

               <!-- back to login -->
            <div
              class="label-size link"
              style="border-top: 1px solid #ddd; padding-top: 20px; width:100%"
              @click="showlogin"
            >
              {{
                getlang.store.login
              }}
            </div>

            </div>

            <div
              v-if="msg_fail"
              class="label-size"
              style="
                display: flex;
                justify-content: center;
                padding: 20px 0px;
                color: red;
              "
            >
            <fas icon='exclamation-circle'></fas>&nbsp;&nbsp;
              {{ getlang.store.cannotregister }}
            </div>

          <!-- 
            <button class="modal-default-button" @click="$emit('close')">
                OK
              </button>
           -->
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import axios from "axios";

import { createToast } from "mosha-vue-toastify";
// import the styling for the toast
import "mosha-vue-toastify/dist/style.css";
import OtpInput from "@/components/OtpInput.vue";

import {firebase} from "../assets/firebase";

import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
initializeApp(firebase.firebaseConfig);
const auth = getAuth();
export default {
  components: {
    "otp-input": OtpInput,
  },
   props: {
    onLogin: Function,
  },
  data() {
    return {
      appjs: window.appjs,

      //form
      showform: true,
      enableBtnStart: true,
      showPassword: false,
      showotp: false,
      pwdinvalid:'',
      formData:{},
      cphone: "",
      password: "",
      email: "",
      realname: "",
      address: "",
      referrer: "",
      //
      request_loading: false,
      msg_success: false,
      msg_fail:false,
    };
  },
  methods: {
    checkValidation() {
      if (this.cphone.length > 0) {
        window.$("#cphone").removeClass("is-invalid");
      }

      if (this.password.length > 0) {
        
        window.$("#password").removeClass("is-invalid");
      }

      if (this.realname.length > 0) {
        window.$("#realname").removeClass("is-invalid");
      }
    },
    //
    initReCaptcha() {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "sign-in-button",
        {
          size: "invisible",
        },
        auth
      );
      this.appVerifier = window.recaptchaVerifier;
    },
    //
    async sendOtp() {
      let validation = true;
      if (this.cphone == "" || this.cphone == null) {
        window.$("#cphone").addClass("is-invalid");
        validation = false;
      }
      if (this.password == "" || this.password == null) {
        this.pwdinvalid = this.getlang.store.needpass;
        window.$("#password").addClass("is-invalid");
        validation = false;
      }
      else if(this.password.length>1 && this.password.length<6){
        this.pwdinvalid = this.getlang.store.need_6digit;
        window.$("#password").addClass("is-invalid");
        validation = false;
      }

      

      if (this.realname == "" || this.realname == null) {
        window.$("#realname").addClass("is-invalid");
        validation = false;
      }

      if (validation) {
        this.enableBtnStart = !this.enableBtnStart;
        const langKey = this.appjs.currentLang();
        const shopname = this.config.dbusing.dbname;
        let phoneNumber = this.cphone;
        phoneNumber = phoneNumber.replace("+855", "");
        if (
          this.appjs.isNum(phoneNumber) &&
          (phoneNumber.length == 9 || phoneNumber.length == 10)
        ) {
          await axios
            .get(
              this.$store.state.apiurl +
                "/storeapi/" +
                shopname +
                "/checkphone?cphone=" +
                phoneNumber +
                "&lang=" +
                langKey
            )
            .then((response) => {
              console.log(response.data);
              if (!response.data.status == true) {
                let that = this;
                let countryCode = "+855"; //Cambodia
                phoneNumber = countryCode + this.cphone;
                
                //start send sms
              signInWithPhoneNumber(auth, phoneNumber, this.appVerifier)
                .then(function (confirmationResult) {
                     /*aaa*/
                  that.showform = false;
                  that.showotp = true;
                  that.formData = {
                    cphone: that.cphone,
                    latinname: that.realname,
                    cpassword: that.password,
                    caddress: that.address,
                    cemail: that.email,
                    cnote: "online register",
                    referrer: that.referrer,
                  };
                /*bbb*/
                  window.confirmationResult = confirmationResult;
                  createToast(
                    { description: that.getlang.store.checksms },
                    { type: "success" }
                  );
                })
                .catch(function (error) {
                  console.log(error);
                  createToast(
                    { description: that.getlang.store.wrongphone },
                    { type: "danger" }
                  );
                });
             
              } else {
                createToast(
                  { description: this.getlang.store.phoneistoken },
                  { type: "danger" }
                );
              }
            })
            .catch((error) => console.log(error));

          this.enableBtnStart = true;
        } else {
          createToast(
            { description: this.getlang.store.invalidphone },
            { type: "danger" }
          );
        }
      }
    },
    //
    async completeOtp(code) {
      window.confirmationResult
        .confirm(code)
        .then(async () => {
          // User signed in successfully.
          await this.submitRegister();
          // ...
        })
        .catch((error) => {
          createToast(
            { description: this.getlang.store.wrongotp },
            { type: "danger" }
          );
          console.log(error);
        });
    },
    // 
     async submitRegister(){
         /*=====================*/
          //show loading
          this.showotp = false;
          this.request_loading = true;

          const langKey = this.appjs.currentLang();
          let shopname = this.config.dbusing.dbname;
          this.formData.lang = langKey;
          this.formData.token = "!555524!";

          await axios
            .post(
              this.$store.state.apiurl + "/storeapi/" + shopname + "/register",
              this.formData,
              {
                headers: this.$store.state.headers,
              }
            )
            .then((response) => {
              if (response.status == 200) {
                if(response.data.success){
                    this.request_loading = false;
                    this.msg_success = true;
                }
                else{
                  this.request_loading = false;
                  this.msg_fail = true;
                  createToast(
                    { description: response.data.message },
                    { type: "danger" }
                  );
                }
                
              }
              else{
                this.request_loading = false;
                this.msg_fail = true;
                createToast(
                    { description: "no internet" },
                    { type: "danger" }
                  );
              }
            })
            .catch((error) => {
              if (error.response) {
                //console.log(error.response.data.message);
                //console.log(error.response.status);
                //console.log(error.response.headers);
                if (error.response.status == 401) {
                  let message = error.response.data.message;
                  createToast({ description: message }, { type: "danger" });
                } else if (error.response.status == 500) {
                  createToast(
                    { description: "no internet" },
                    { type: "danger" }
                  );
                }
              } else {
                createToast(
                  { description: "something's wrong" },
                  { type: "danger" }
                );
              }
            });
          /*=====================*/
     },
    //  
     //
    showlogin() {
      this.onLogin();
    },
    // 
  },
  computed: {
    getlang() {
      return this.appjs.getlang();
      //return this.$store.state.getlang;
    },
  },

  mounted() {
    this.initReCaptcha();
  },

};
</script>

<style scoped>
.form-group {
  margin-bottom: 0px !important;
}
.required {
  color: red;
}
</style>
