<template>
  <div class="flex-container">
    <!-- Header -->
    <!-- <div style="display:flex; width:100%; background-color:red">
      header
    </div> -->
    <div class="flex-body">
      <!-- Left -->
      <div class="flex-side-left">
        <!-- logo -->
        <div style="display: flex; flex-direction: column">
          <div>
            <img width="90" :src="logo" />
          </div>
          <div>
            {{ companyname }}
          </div>
          <div>
            <menu-left></menu-left>
          </div>
        </div>
      </div>
      <!-- Center -->
      <div v-show="showcenter" class="flex-side-center">
        <!-- HEADER -->
        <div style="display: flex; flex-grow: 0; width: 100%">
          <header-home
            :onPress="filteredText"
            :cartClick="showcart"
            :menuClick="showmenu"
            :logo="logo"
            :cartCount="cartList.length"
            @showlogin="modalLogin = true"
             @showregister="modalRegister = true"
          ></header-home>
        </div>

        <!-- category -->
        <div style="flex-grow: 0; width: 100%">
          <div class="sticky">
            <div class="category-list">
              <div class="item active" @click="filteredCategory(0)" id="cat_0">
                {{ getlang.store.home }}
              </div>
              <div
                v-for="category in categories"
                :key="category.c_id"
                :id="'cat_' + category.c_id"
                class="item"
                @click="filteredCategory(category.c_id)"
              >
                {{ category.title }}
              </div>
            </div>
          </div>
        </div>

        <!-- product -->
        <div
          style="flex-grow: 1; overflow-y: scroll; margin: 5px"
          id="itemcontainer"
          @scroll="onScroll"
        >
          <!--  -->

          <div class="flex-item-container">
            <div
              v-for="item in productshowing"
              :key="item.pd_id"
              class="flex-each-item"
              @click.prevent="addtocart(item)"
            >
              <div
                v-if="$store.state.productSetting.imagedisplay"
                class="item-image-container"
              >
                <!-- <div
                  class="item-image"
                  v-bind:style="{
                    'background-image':
                      'url(' +
                      filepath +
                      '/resources/filelibrary/' +
                      productImage(item.imginfo) +
                      ')',
                  }"
                ></div> -->
                <img class="item-image" :src="filepath + '/resources/filelibrary/' + imagname (item.imginfo)"/>
              </div>
              <div
                :class="
                  $store.state.productSetting.imagedisplay
                    ? 'title-part'
                    : 'title-part1'
                "
              >
                <!--  -->
                <div class="title-container">
                  <div class="product-title">
                    <span v-html="appjs.decoder(item.title)"></span>
                  </div>
                </div>

                <div style="height: 15px; font-size: 11px; text-align: left">
                  {{ item.priceformat }}/{{ item.unit }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  -->

        <!-- end CENTER -->
      </div>

      <!-- Right -->
      <div class="flex-side-right">
        <div
          style="
            display: flex;
            height: 60px;
            padding: 5px;
            margin-bottom: 5px;
            background-color: #f3f3f3;
            justify-content: space-between;
          "
        >
          <!-- show when mobile view -->
          <div class="cart-back-button">
            <div
              @click="showhome"
              style="
                display: flex;
                color: var(--main-color);
                font-size: 22px;
                padding: 0px 10px;
                justify-content: center;
                align-items: center;
              "
            >
              <fas icon="chevron-left"></fas>
            </div>
            <div style="padding: 0px 10px" @click="showhome">
              <img width="50" :src="logo" />
            </div>
          </div>
          <!--  -->
          <div style="display: flex; flex-grow: 1">
            <!-- welcome & item in cart -->
            <div
              style="
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                width: 100%;
              "
            >
              <div class="welcome-and-item">
                <div style="color: var(--main-color)">
                  {{ getlang.store.welcome }}
                </div>
                <div style="color: var(--sub-color)">{{welcometo}}</div>
              </div>

              <div class="welcome-and-item">
                <div style="color: var(--main-color)">
                  {{ getlang.store.itemincart }} / <span style="font-size:14px; color:var(--sub-color)">{{ cartList.length }}</span>
                </div>
                <div>
                  <span class="btn-remove-color" @click="removeItem">
                    <fas
                      style="font-size: 16px"
                      icon="trash"
                      @click="emptyCart"
                    ></fas>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Cart  -->
        <div class="cart-item-container">
          <!-- 3 -->
          <cart-item
            v-for="(item, index) in cartList"
            :key="index"
            :defaultCartItem="item"
            :index="index"
          >
          </cart-item>
          <!-- 3 -->
        </div>

        <div class="bottom-checkout">
          <bottom-cart></bottom-cart>
        </div>
      </div>
    </div>
  </div>

  <!-- footer -->
  <div class="flex-footer">
    <footer-home
      :showcenter="showcenter"
      :cartCount="cartList.length"
      :onBack="showhome"
      :onCart="showcart"
      @showlogin="modalLogin = true"
      @showregister="modalRegister = true"
    ></footer-home>
  </div>

  <modal-sizecolor
    v-if="showModal"
    :product="productSizeColorModal"
    @close="showModal = false"
  />

  <login v-if="modalLogin" @close="modalLogin = false" :onForget="showforget"></login>
  <register v-if="modalRegister" @close="modalRegister = false" :onLogin="showlogin"></register>
  <forget v-if="modalForget" @close="modalForget = false" :onLogin="showlogin"></forget>
    <modal-menu v-if="showModalMenu" @close="showModalMenu = false"></modal-menu>
</template>

<script>
// @ is an alias to /src
import HeaderHome from "@/components/HeaderHome.vue";
import FooterHome from "@/components/FooterHome.vue";
import ModalSizeColor from "@/components/ModalSizeColor.vue";
import CartItem from "@/components/CartItem.vue";
import BottomCart from "@/components/BottomCart.vue";
import Login from "@/components/Login.vue";
import Register from "@/components/Register.vue";
import Forget from "@/components/Forget.vue";
import Menu from "@/components/Menu.vue";
import ModalMenu from "@/components/ModalMenu.vue";

export default {
  name: "Home",
  components: {
    "header-home": HeaderHome,
    "footer-home": FooterHome,
    "modal-sizecolor": ModalSizeColor,
    "cart-item": CartItem,
    "bottom-cart": BottomCart,
    "login": Login,
    "register": Register,
    "forget" : Forget,
    "menu-left": Menu,
    "modal-menu": ModalMenu,
  },
  data() {
    return {
      appjs: window.appjs,
      initdata: JSON.parse(localStorage.getItem("initdata")),
      all_size: [],
      all_color: [],
      sysconfig: JSON.parse(localStorage.getItem("sysconfig")),
      currencyinfo: JSON.parse(localStorage.getItem("currencyinfo")),
      filepath: "",
      categories: [],
      all: 0,
      lastpage: 0,
      showmore: true,
      currentpage: 1,
      perpage: 20,
      productlists: [],
      productshowing: [],
      showModal: false,
      productSizeColorModal: {},

      //
      logo: "",
      contact: {},
      companyname: "",
      companyname_latin: "",

      //
      modalLogin: false,
      modalRegister: false,
      modalForget: false,
      showModalMenu:false,
      //
      showcenter: true,

      //
      logined: this.$store.state.authentcation,
      welcometo: '',

    };
  },
  computed: {
    getlang() {
      return this.appjs.getlang();
      //return this.$store.state.getlang;
    },

    cartList() {
      return this.$store.state.cartList;
    },

    lastcalculate() {
      return this.$store.state.lastCalculate;
    },

    pageinfo() {
      return JSON.parse(localStorage.getItem("pageinfo"));
    },
  },
  methods: {
     bannerBgImage(image){
      return "background-image: url("+ encodeURI(this.filepath+ "/resources/filelibrary/"+image)+")";
  },
    imagname(imginfo){
      if(imginfo==null || imginfo=='' || imginfo=='{}'){
        return 'system/nopic.png';
      }
      const imginfo_obj = JSON.parse(imginfo);
      
      if(imginfo_obj.file_name=='undefined'){
        return 'system/nopic.png';
      }
      return imginfo_obj.file_name;
    },
    productImage(imginfo){
      
      let image = 'system/nopic.png';
      if(imginfo != null && imginfo != ''){
        let json_imginfo = JSON.parse(imginfo);
        if(json_imginfo){
          image = json_imginfo.file_name;
        }
        else{
          image = 'system/nopic.png';
        }
        
      }
      return image;
    },
    filteredCategory(c_id) {
      window.$(".item").removeClass("active");
      window.$("#cat_" + c_id).addClass("active");
      let result;
      if (c_id == 0) result = this.initdata.results;
      else
        result = this.initdata.results.filter(
          (product) => product.c_id == c_id
        );
      this.displayProduct(result);
    },
    //
    filteredText(keyword) {
      let result;
      window.$(".item").removeClass("active");
      window.$("#cat_0").addClass("active");
      if (keyword.length == 0) {
        result = this.initdata.results;
      } else if (keyword.length >= 3 && !this.appjs.isNum(keyword)) {
        result = this.initdata.results.filter((item) => {
          let productcode = JSON.parse(item.tag).productcode;
          return (
            item.title.toLowerCase().includes(keyword.toLowerCase()) ||
            (productcode &&
              productcode.toLowerCase().includes(keyword.toLowerCase()))
          );
        });
      } else if (this.appjs.isNum(keyword)) {
        result = this.initdata.results.filter((product) => {
          return product.id == keyword || product.barcode == keyword;
        });
      } else {
        result = this.initdata.results;
      }

      if (result && result.length > 0) {
        this.displayProduct(result);
      }
    },
    //
    displayProduct(result) {
      //console.log(this.$store.state.productSetting.numperpage);
      this.all = 0;
      this.lastpage = 0;
      this.showmore = true;
      this.currentpage = 1;

      this.productlists = [];
      this.productshowing = [];
      this.perpage = this.$store.state.productSetting.numperpage;
      if (result.length > 0) {
        this.productlists = result;
        this.all = this.productlists.length;

        this.lastpage = Math.ceil(this.all / this.perpage);
        if (this.all < this.perpage) {
          this.perpage = this.all;
          this.showmore = false;
        }
      }
      if (this.all > 0) {
        for (let i = 0; i < this.perpage; i++) {
          this.productshowing.push(this.productlists[i]);
        }
      }
    },
    //

    more() {
      let from = this.currentpage * this.perpage;
      this.currentpage += 1;
      if (this.currentpage > this.lastpage) return;
      let to = this.currentpage * this.perpage;
      if (to > this.all) {
        to = this.all;
        this.showmore = false;
      }
      for (let i = from; i < to; i++) {
        this.productshowing.push(this.productlists[i]);
      }
      var d = window.$("#itemcontainer");
      d.animate({ scrollTop: d.prop("scrollHeight") + 50 }, 1000);
    },

    //

    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight) {
        if (this.showmore) {
          this.more();
        }
      }
    },
    //

    addtocart(item) {
      if (item.sizes.length > 0 || item.colors.length > 0) {
        this.productSizeColorModal = item;
        this.showModal = true;
      } else {
        this.appjs.playSound(this.filepath+'/resources/sound/button-35.mp3')
        if (
          item.madewith.length > 3 &&
          this.sysconfig.madewithtocart == "sub"
        ) {
          let madewith = JSON.parse(item.madewith);
          if (madewith) {
            for (let madewithItem in madewith) {
              const productSizeColor = madewithItem.split("-");

              let filtered = this.initdata.results.filter((product) => {
                return product.id == productSizeColor[0];
              });

              if (filtered) {
                let product = this.appjs.initProduct(
                  filtered[0],
                  1,
                  -1,
                  this.all_size,
                  this.all_color
                );
                product.subqty = madewith[madewithItem];
                let cartItem = this.appjs.initialCartItem(
                  product,
                  0,
                  0,
                  this.currencyinfo,
                  this.sysconfig
                );

                this.appjs.addCartItemlToList(cartItem, this.currencyinfo);
              }
            }
          }
        } else {
          let product = this.appjs.initProduct(
            item,
            1,
            -1,
            this.all_size,
            this.all_color
          );
          let cartItem = this.appjs.initialCartItem(
            product,
            0,
            0,
            this.currencyinfo,
            this.sysconfig
          );

          this.appjs.addCartItemlToList(cartItem, this.currencyinfo);
        }
      }
    },
    //
    emptyCart() {
      this.appjs.playSound(this.filepath+'/resources/sound/button-20.mp3')
      this.appjs.emptyCartList(this.currencyinfo);
    },
    //
    showcart() {
      if (
        window.$(".flex-side-right").is(":hidden") ||
        window.$(".flex-side-right").hasClass("hide")
      ) {
        //console.log('aaaa');
        // window.$(".flex-side-center").hide();
        // window.$(".flex-side-center").addClass('hide');

        // window.$(".flex-side-right").show();
        // window.$(".flex-side-right").removeClass("hide");
        // window.$(".flex-side-right").addClass("flex-side-right-alone");

        this.showcenter = false;

        window.$(".flex-side-right").show();
        window.$(".flex-side-right").removeClass("hide");
        window.$(".flex-side-right").addClass("flex-side-right-alone");
      }
    },
    //
    showhome() {
      //console.log('bbb');
      // window.$("body").addClass('body');
      // window.$(".flex-side-right").removeAttr("style");
      // window.$(".flex-side-right").addClass('hide');

      // window.$(".flex-side-center").removeClass("hide");
      // window.$(".flex-side-center").removeAttr("style");
      // window.$(".flex-side-center").addClass('flex-important');

      //this.$router.replace({path:'/'});

      this.$router.replace({ path: "/refresh" });
    },
    //
    showforget(){
      this.modalLogin = false;
      this.modalForget = true;
    },
    // 
     showlogin(){
       this.modalForget = false;
       this.modalRegister = false;
      this.modalLogin = true;
    },
    // 
    showmenu(){
      this.showModalMenu = true;
    },
    // 

  },
   mounted() {
    
    this.$store.dispatch("initProductSetting");
    //console.log(this.config.dbusing.dbname);
    //console.log(this.pageinfo.contact);
    this.filepath = this.$store.state.apiurl;
    this.logo = this.filepath + "/resources/filelibrary/" + this.pageinfo.logo;
    this.contact = JSON.parse(this.pageinfo.contact);
    if (this.contact.latinname) {
      this.companyname = this.contact.latinname;
    } else {
      this.companyname = this.contact.nativename;
    }

    //change welcome
    this.welcometo = this.getlang.store.generalcustomer;
    if(this.logined){
      const customerinfo = localStorage.getItem('customerinfo')
      if(customerinfo==null || customerinfo==''){
        this.welcometo = this.getlang.store.generalcustomer;
      }
      else{
        const customer = JSON.parse(customerinfo);
        if(customer.nativename==''){
          this.welcometo = customer.latinname
        }
        else{
          this.welcometo = customer.nativename + '/' + customer.latinname;
        }
      }
    }

    this.appLoading = false;
    this.categories = this.initdata.cat_tree;
    this.all_size = this.initdata.allsizes;
    this.all_color = this.initdata.allcolors;

    this.displayProduct(this.initdata.results);
    this.appjs.initCart(this.currencyinfo);
    window.$(document).ready(function () {
      window.appjs.scrollTitle("title-container", "product-title");
    });

    

  },
};
</script>

<style scoped>


.image-part{
  flex-grow:1;
  padding:3px;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  background-color:#fff;
  margin:0px;
}
.item-image {
  /* display:flex; */
  /* border:1px solid red; */
  /* flex-direction:column; */
  /* background-size: contain;
  background-position: top center;
  background-repeat: no-repeat; */
  /* background-image: url(/resources/filelibrary/arsenal-pre-match-jersey.jpg); */
  border-radius: 7px 7px 0px 0px;
  height: 100%; width: 100%; object-fit: contain;
}

.sticky {
  flex-grow: 0;
  height: 50px;
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
  top: 0;
  /* background-color: rgb(201, 21, 21); */
  overflow: hidden;
  width: inherit;
  padding-right: 0px;
}

/**category**/
.category-list {
  display: flex;
  overflow-x: auto;
  margin: 0px 5px;
  padding-bottom: 5px;
  width: auto;
}
.category-list::-webkit-scrollbar {
  width: 5px;
  height: 6px;
  background-color: #aaa;
  border-radius: 5px;
}

.category-list::-webkit-scrollbar-thumb {
  background: rgb(170 170 170);
  background: linear-gradient(
    90deg,
    rgb(170 170 170) 0%,
    rgb(170 170 170) 35%,
    #005b7a 100%
  );
  border-radius: 5px;
}
.category-list .item:hover,
.category-list .item:active,
.category-list .item:visited,
.category-list .item:focus,
.category-list .active {
  background: #005b7a;
  color: #fff !important;
}

.category-list .item {
  flex: 0 0 auto;
  /* border:1px solid #005b7a; */
  color: #005b7a;
  margin-right: 10px;
  border-radius: 5px;
  line-height: 35px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  transition: 0.5s all;
  cursor: pointer;
  font-size: 15px;
  /* -webkit-box-shadow: 0 4px 10px rgb(10 36 99 / 14%);
    box-shadow: 0 4px 10px rgb(10 36 99 / 14%); */
}
.category-list .item span.back {
  font-size: 11px;
  border-radius: var(--border-radius);
  padding: 2px 7px;
  margin-left: 11px;
  border: 1px solid #505050;
  color: #505050;
  display: none;
}
.category-list .item.active span.back {
  border: 1px solid #ffffff;
  color: #ffffff;
}

.flex-item-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  /* background-color: DodgerBlue; */
}

.flex-each-item {
  flex-grow: 1;
  background-color: #005b7a;
  border: 1px solid #000;
  border-radius: 7px;
  margin: 10px;
  min-width: 220px;
  max-width: 290px;

  cursor: pointer;
}
.flex-each-item:hover {
  color: var(--sub-color);
}
.item-image-container {
  width: 100%;
  background-color: #fff;
  border-radius: 7px 7px 0px 0px;
  height: 141px;
}
.item-image {
  border-radius: 7px 7px 0px 0px;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  /* background-image: url(/resources/filelibrary/arsenal-pre-match-jersey.jpg); */
}

.title-part {
  display: flex;
  flex-direction: column;
  height: 40px;
  background-color: #005b7a;
  border-color: #005b7a;
  border-radius: 0px 0px 7px 7px;
  padding: 0px 5px;
}

.title-part1 {
  display: flex;
  flex-direction: column;
  height: 40px;
  background-color: #005b7a;
  border-color: #005b7a;
  border-radius: 7px 7px 7px 7px;
  padding: 0px 5px;
}
.title-container {
  position: relative;
  overflow-x: hidden;
  height: 25px;
  font-size: 14px;
}
.product-title {
  position: absolute;
  white-space: nowrap;
  overflow-x: auto;
  transform: translateX(0px);
  transition: transform 2s;
}

#itemcontainer::-webkit-scrollbar {
  width: 6px;
  height: 5px;
  background-color: #aaa;
  border-radius: 5px;
}

#itemcontainer::-webkit-scrollbar-thumb {
  background: rgb(170 170 170);
  background: linear-gradient(
    90deg,
    rgb(170 170 170) 0%,
    rgb(170 170 170) 35%,
    #005b7a 100%
  );
  border-radius: 5px;
}

/*  */

.welcome-and-item {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  font-size: 12px;
  font-weight: bold;
}

.cart-item-container {
  /* flex-grow: 1 !important; */
  padding: 10px;
  margin: 3px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 85%;
}
.cart-item-container::-webkit-scrollbar {
  width: 6px;
  height: 5px;
  background-color: #aaa;
  border-radius: 5px;
}

.cart-item-container::-webkit-scrollbar-thumb {
  background: rgb(170 170 170);
  background: linear-gradient(
    90deg,
    rgb(170 170 170) 0%,
    rgb(170 170 170) 35%,
    #005b7a 100%
  );
  border-radius: 5px;
}

.cart-back-button {
  display: none;
}

.bottom-checkout {
  position: fixed;
  height: 40px;
  width: 350px;
  bottom: 6px;
  padding: 0px 10px;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .cart-back-button {
    display: flex;
    flex-grow: 1;
  }
}

@media (max-width: 700px) {
  .flex-each-item {
    min-width: 140px;
    max-width: 175px;
  }

  .bottom-checkout {
    width: 100%;
    bottom: 7.5vh;
  }
}

@media (min-width: 768px) {
  .flex-each-item {
    min-width: 210px;
  }
}


/*  */


</style>
