<template>
  <div
    style="
      display: flex;
      align-items: stretch;
      height: 60px;
      width: 100%;
      padding: 5px;
      margin-bottom: 5px;
      background-color: #f3f3f3;
    "
  >
    <!-- Show Logo at mobile screen -->
    <div class="show-1024">
      <div
        style="
          display: flex;
          justify-content: center;
          align-items: center;
          color: var(--main-color);
          font-size: 22px;
          padding: 0px 10px;
        "
        @click="showMenu"
      >
        <fas icon="bars"></fas>
      </div>
      <div style="padding: 0px 10px">
        <img width="50" :src="logo" />
      </div>
    </div>
    <!-- Search -->
    <div style="flex-grow: 1; padding-top: 5px">
      <input
        type="text"
        id="search-filter"
        name="search"
        class="form-control searchbox input-no-focus"
        :placeholder="getlang.store.search"
        autocomplete="off"
        v-model="keyword"
        @input="lookup"
      />
    </div>
    <!-- cart -->
    <div style="display: flex; flex-grow: 0; justify-content: flex-start; margin-left:20px;">
      <div class="icon-menu" style="position: relative" @click="showcart">
        <div><fas class="icon-menu-icon-size" icon="shopping-cart"></fas></div>
        <div class="icon-menu-text-size">{{ getlang.store.cart }}</div>
        <div class="badge">{{ cartCount ?? 0 }}</div>
      </div>
    </div>
    <!--  -->
    <div class="hide-1024" style="flex-grow: 0; justify-content: space-evenly">
      <div v-if="!logined" class="icon-menu" @click="$emit('showlogin')">
        <div><fas class="icon-menu-icon-size" icon="sign-in-alt"></fas></div>
        <div class="icon-menu-text-size">{{ getlang.store.login }}</div>
      </div>

      <div v-if="!logined" class="icon-menu" @click="$emit('showregister')">
        <div><fas class="icon-menu-icon-size" icon="user-plus"></fas></div>
        <div class="icon-menu-text-size">{{ getlang.store.register }}</div>
      </div>

      <div v-if="logined" class="icon-menu" @click="gotoOrdered">
        <div><fas class="icon-menu-icon-size" icon="clipboard-list"></fas></div>
        <div class="icon-menu-text-size">{{ getlang.store.myorder }}</div>
      </div>

      <div v-if="logined" class="icon-menu" @click="appjs.logout">
        <div><fas class="icon-menu-icon-size" icon="sign-out-alt"></fas></div>
        <div class="icon-menu-text-size">{{ getlang.store.logout }}</div>
      </div>
    </div>
  </div>


</template>

<script>

export default {
  props: {
    onPress: Function,
    cartClick: Function,
    menuClick: Function,
    cartCount: {
      typeof: Number,
      required: true,
    },
    logo: {
      typeof: String,
      required: true,
    },
  },

  components: {
    
  },

  data() {
    return {
      appjs: window.appjs,
      logined: this.$store.state.authentcation,
      keyword: "",
    };
  },
  computed: {
    getlang() {
      return this.appjs.getlang();
    },
  },

  methods: {
    lookup() {
      this.onPress(this.keyword);
    },
    showcart() {
      this.cartClick();
    },
    gotoOrdered() {
      this.$router.push({ path: "/ordered" });
    },
    //
    showMenu(){
      this.menuClick();
    }
  },

  mounted() {
   
  },
};
</script>


<style scoped>
.searchbox {
  border-radius: 30px !important;
  /* border-right: none; */
  /* background: #f4f4f4;*/
}

.icon-menu {
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 5px;
  color: var(--main-color);
  cursor: pointer;
}
.icon-menu:hover {
  color: var(--sub-color);
}

.show-1024 {
  display: none;
}

.hide-1024 {
  display: flex;
}
@media (max-width: 1024px) {
  .show-1024 {
    display: flex;
    flex-grow: 0;
    justify-content: space-evenly;
  }
  .hide-1024 {
    display: none;
  }
}
</style>