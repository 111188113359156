<template>
  <div
    style="
      display: flex;
      flex-direction: column;
      width: 100%;
      text-align: left;
      margin-top: 20px;
    "
  >
    <div v-show="multilang.length > 0" class="btn-group dropright">
      <button
        type="button"
        class="btn btn-secondary dropdown-toggle input-no-focus"
        data-toggle="dropdown"
        aria-haspopup="false"
        aria-expanded="true"
        style="text-align: left"
      >
        <fas icon="globe"></fas>
        {{ getlang.store.language }}
      </button>
      <div class="dropdown-menu">
        <a
          v-for="(lang, index) in multilang"
          :key="index"
          class="dropdown-item"
          href="#"
          @click="changLang(lang[0])"
          >{{ lang[2] }}</a
        >
  
      </div>
    </div>

    <!--  -->
    
  </div>
</template>

<script>
export default {
  setup() {},
  computed: {
    getlang() {
      return this.appjs.getlang();
      //return this.$store.state.getlang;
    },
    pageinfo() {
      return JSON.parse(localStorage.getItem("pageinfo"));
    },
  },
  data() {
    return {
      appjs: window.appjs,
      currencyinfo: JSON.parse(localStorage.getItem("currencyinfo")),
      sysconfig: JSON.parse(localStorage.getItem("sysconfig")),
      multilang: JSON.parse(localStorage.getItem("multilang")),
    };
  },
  methods: {
    changLang(langkey) {
      localStorage.setItem("lang", langkey);
      this.$router.push({ path: "/" });
    },
  },
  mounted() {
    //console.log(this.multilang);
  },
};
</script>


<style scoped>
.dropright .dropdown-toggle::after {
  vertical-align: 0;
  right: 10px;
  position: absolute;
  top: 40%;
}
</style>